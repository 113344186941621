import React from 'react';
import './results.css';

const Results = ({totalResults, openHelp}) => {
    return(
        <>
            {
              totalResults > 0 ?
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height:'100%', alignItems: 'center'}}>
                {/* <p style={{color: 'tomato', cursor: 'pointer', fontWeight:'bolder', fontSize: '130%', justifySelf: 'flex-end', opacity: '0'}}>?</p> */}
                <p className='resultsFontStyle'>totalResults: {totalResults}</p>
                {/* <p onClick={openHelp} style={{color: 'tomato', cursor: 'pointer', fontWeight:'bolder', fontSize: '130%', justifySelf: 'flex-end', marginRight: '1rem'}}>?</p> */}
              </div>
                :
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', height:'100%', alignItems: 'center'}}>
                  <p className='resultsFontStyle'>no recipes meet criteria. please try again...</p>
                </div>
            }
        </>
    );
}

export default Results;