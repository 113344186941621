import React, {useState, useEffect} from 'react';
import './signin.css';
import SigninHeader from './SigninHeader';

const Signin = ({loaduser, updateResults, cookbook, isSignedIn, groceryList}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [signUpClicked, setSignUpClicked] = useState(false);

    const [signUpEmail1, setSignUpEmail1] = useState('');
    const [signUpPassword1, setSignUpPassword1] = useState('');
    const [signUpEmail2, setSignUpEmail2] = useState('');
    const [signUpPassword2, setSignUpPassword2] = useState('');
    const [loginBtn, setLoginBtn] = useState('logIn');
    const [signupBtn, setSignupBtn] = useState('signUp')

    const signInScreenReset = () => {
        setEmail('');
        setPassword('');
        setSignUpEmail1('');
        setSignUpPassword1('');
        setSignUpEmail2('');
        setSignUpPassword2('');
    }

    useEffect(() => {
        signInScreenReset();
    }, [isSignedIn])

    const switchSignInScreen = (e) => {
        if(e.target.title === "signUp"){
            signInScreenReset();
            setSignUpClicked(true);
        }else if (e.target.title === "logIn"){
            signInScreenReset();
            setSignUpClicked(false);
        }
    }

    const signedIn = (e) => {
        if(e.target.title === 'loginBtn' || e.target.title === 'passwordField'){
            fetch('https://protected-temple-53634.herokuapp.com/signin', {
                method: 'post', 
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    email: email,
                    password: password
                })
            })
            .then(res => res.json())
            .then(data => {
                if (data.id){
                    loaduser(data);
                    document.getElementById('signInID').style.opacity = '0';
                    document.getElementById('signInID').style.height = '0';
                    document.getElementById('signInID').style.zIndex = '0';
                    document.getElementById("mySidenav").style.width = "0";
                    document.getElementById("mySidenav").style.zIndex = "0";  
                    document.getElementById("myMealPrepSidenav").style.width = "0";
                    document.getElementById("myMealPrepSidenav").style.zIndex = "0";
                    document.getElementById("myHelpCenterNav").style.width = "0";
                    document.getElementById("myHelpCenterNav").style.zIndex = "0";
                    document.getElementById("myCookBookSidenav").style.width = "0";        
                    document.getElementById("myCookBookSidenav").style.zIndex = "0";
                    fetch(
                        `https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&sort=random&number=100&apiKey=589f2dc10359411092bf6266b33c169a`
                      )
                        .then(response => response.json())
                        .then(data => updateResults(data))
                        .catch(() => {
                          console.log("error")
                        })
                }else{
                    document.getElementById('signInID').style.height = 'auto';
                    document.getElementById('signInID').style.opacity = '1';
                    setLoginBtn('nope! Try Again!'); 
                    setTimeout(() => {
                        setLoginBtn('logIn');
                    }, 2000);
                }
            }).catch(err =>  console.log(err))
        }else if (e.target.title === 'signUpBtn'){
            fetch('https://protected-temple-53634.herokuapp.com/register', {
                method: 'post', 
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    signUpEmail: signUpEmail1,
                    signUpPassword: signUpPassword1,
                    cookbook: cookbook,
                    grocerylist: groceryList
                })
            })
            .then(res => res.json())
            .then(user => {
                if (user.id){
                    loaduser(user);
                    setSignupBtn('Noms');
                    document.getElementById('signInID').style.opacity = '0';
                    document.getElementById('signInID').style.height = '0';
                    document.getElementById('signInID').style.zIndex = '0';
                    fetch(
                        `https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&sort=random&number=100&apiKey=589f2dc10359411092bf6266b33c169a`
                      )
                        .then(response => response.json())
                        .then(data => {
                            updateResults(data);
                        })
                    setTimeout(()=>{
                        setSignupBtn('Noms');
                    }, 2000)
                }else {
                    setSignupBtn('email taken');
                    setTimeout(()=> {
                        setSignupBtn('signUp')
                        signInScreenReset();
                    }, 3000)
        
                }
            }).catch(console.log('error registering!'));
        }
    }

    const keyboardEvents = (e) => {
        if(e.target.title === 'passwordField'){
            if(e.code === 'Enter'){
                signedIn(e);
              }
        }else if (e.target.title === 'loginPassword2Field' && signUpEmail1===signUpEmail2 && signUpPassword1===signUpPassword2){
            if(e.code === 'Enter'){
                signedIn(e);
              }
        }
      }
    return(
        <div id="signInID" className='signInContainer'>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <SigninHeader signUpClicked={signUpClicked} loginBtn={loginBtn} switchSignInScreen={switchSignInScreen}/>
            </div>
            {!signUpClicked?
            <div id="logInScreen" className='signInScreenContainer signUpFade'>
                <input onChange={(e) => setEmail(e.target.value)} className='usernameInput' type='email' title='emailField' placeholder='email...' value={email} autoFocus/>
                <input onKeyDown={(e) => keyboardEvents(e)} onChange={(e) => setPassword(e.target.value)} className='usernameInput' type='password' title="passwordField" placeholder='password...' value={password}/>
                <div style={{marginTop: '0.5rem'}}>
                {email.length > 7 && password.length > 7?
                    <div title="loginBtn" onClick={(e) => signedIn(e)} className='btn'>{loginBtn}</div>
                    :
                    <div title="loginBtn" className='btnO btnOcolor'>{loginBtn}</div>
                }
                </div>
            </div>
            :
            <div id="signUpScreen" className='signUpScreenContainer signUpFade'>
                <input onChange={(e) => setSignUpEmail1(e.target.value)} className='usernameInputLogin' type='email' title='loginEmail1Field' placeholder='email?' value={signUpEmail1}/>
                <input onChange={(e) => setSignUpEmail2(e.target.value)} className='usernameInputLogin' type='email' title='loginEmail2Field' placeholder='confim email...' value={signUpEmail2}/>
                <input onChange={(e) => setSignUpPassword1(e.target.value)} className='usernameInputLogin' type='password' title="loginPassword1Field" placeholder='password?' value={signUpPassword1}/>
                <input onKeyDown={(e) => keyboardEvents(e)} onChange={(e) => setSignUpPassword2(e.target.value)} className='usernameInputLogin' type='password' title="loginPassword2Field" placeholder='confirm password...' value={signUpPassword2}/>
                    <div title="signUpBtn" onClick={(e) => signedIn(e)} className={((signUpEmail1 === signUpEmail2) && (signUpPassword1 === signUpPassword2) && (signUpEmail1.length > 7 && signUpEmail2.length > 7 && signUpPassword1.length > 7 && signUpPassword2.length > 7))?'btnSmall':'btnNone'}>{signupBtn}</div>
            </div>
        }
        </div>
    );
}

export default Signin;