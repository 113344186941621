import React from 'react';
import Loading from '../Loading';
import Loadmore from '../Loadmore';
import '../../Styles/nomsstyle2.css';
import './recipe.css';


const RecipeCard = ({totalResults, loadMore, recipeOpened, recipeBook, isSignedIn}) => {
    return (
        <div>
            {isSignedIn?       
                <div className="recipeCardBox">
                    {recipeBook.map((recipe, i) => {
                        return (       
                            <div key={i} style={{width: '15vmax', justifyContent: 'center', margin: '0.25rem', padding: '1rem', color: 'tomato', fontWeight: 'bold'}}>            
                                <div style={{flexWrap: 'wrap', alignSelf: 'center', justifySelf: 'center'}}>
                                    <p className='recipeTitleMain'>
                                        {recipe.title}
                                    </p>
                                </div>
                                <img id='notSaved' onClick={recipeOpened} alt={recipe.id} key={i} className="recipeCard" title={recipe.title} src={recipe.image}/> 
                            </div>          
                            )
                        })
                    }
                    <div>
                        {recipeBook.length < 1?
                            <Loading />
                            :  
                            <Loadmore loadMore={loadMore} totalResults={totalResults}/>
                            
                        }
                    </div>
                </div>
            :
                <div>
                    <div onClick={loadMore} style={{cursor: 'pointer', color: 'tomato', alignSelf: 'center', justifyContent: 'center', width: '100vw'}}>Sign In To Recieve Recipes...</div>  
                </div>
            }
        </div>
    );
}

export default RecipeCard;