import React, { Component } from 'react';
import MainPage from './Components/MainPage';
import './App.css';

class App extends Component {
  render(){
    return (
          <div className="App">
            <MainPage />
          </div>
    );
    }
}
  
export default App;