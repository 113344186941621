import React, {useState} from 'react';
import { FaPowerOff } from "react-icons/fa";
import '../GroceryList/groceryList.css';
import '../../Styles/nomsstyle2.css';
import './helpCenter.css';
import '../Recipes/recipe.css';

import orangeCart from '../Assets/Images/groceryListOrangeCart.png';
import calculator from '../Assets/Images/calculator.png';

const HelpCenter = ({powerSwitch, signedIn, closeHelpNav, openNav}) => {
    const [clickedPlate, setClickedPlate] = useState(false);
    const [clickedCart, setClickedCart] = useState(false);
    
    const plateClicked = () => {
        if(clickedPlate === true){
            setClickedPlate(false)
        }else{
            setClickedPlate(true)
        }
    }
    return(
        <>
            <div>
                <div onClick={powerSwitch} style={signedIn?{position: 'absolute', top: '0', right: '0',  margin: '0.5rem 1rem 0rem 0rem', color: 'tomato', cursor: 'pointer', filter: 'brightness(1.2)', fontSize: '125%'}:{position: 'absolute', top: '0', right: '0', margin: '0.5rem 1rem 0rem 0rem', color: 'tomato', filter: 'brightness(0.85)', fontSize: '125%'}}><FaPowerOff /></div>
            </div>
            <div id="myHelpCenterNav" className="sidenavCookBook">
            <div id="openNavButton" onClick={openNav} className='groceryButtonContainer groceryListBtn'>
                    <div className='openGroceryDashes'>
                        &#9776;
                    </div>
                </div>
                <div className="closebtn closeButton" onClick={closeHelpNav}>&times;</div>
                    <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: '0.75rem'}}>
                        <div className='cookBookTitle'>Help Center</div>
                    </div>
                    <div id='helpDetailContainer'>
                            <div style={{paddingBottom: '0.25rem', borderBottom: '0.25rem solid tomato', marginBottom: '0.25rem'}}>
                                <div className='aboutFont'>Noms is the place to find to the most delicious recipes in the world, then store in your personalized Cookbook so you and your family can experience the same mouth watering experience any day a craving desires. 
                                <div> Oh. Just realized you are missing a recipe. No worries! Just hit the name of the ingredient on the recipe and it will store the missing item to your groceryList. Hit the xpan button to...well...expand the grocery list...in order to store the quantity and unit you need to complete the meal you seek to prepare, then devour shortly, after...you...prepare it!</div>
                                    <div> Love it thus far? Leave an email and password and let the food exploration begin! </div> 
                                    <div style={{borderTop: '0.25rem solid tomato'}}> Check out the icons below if you are stuck in a navigation trap:</div> </div>
                            </div>
                            <div className='helpIconOutterContainer'>
                            <div className="helpIconInnerContainer">
                                <div className='iconBox'>
                                    <div className='helpBox iconPushRight'><div style={{fontSize: '3vmax'}} className='openGroceryDashes'>&#9776;</div></div>
                                        <div className="helpFont helpFontPosition">Located Top Left Screen: Tap to open grocery list</div>
                                </div>
                                <div className='iconBox'>
                                    <div className="closeIcon closeButton iconPushRight">&times;</div>
                                    <div className="helpFont helpFontPosition">Located Top Right of Opened Screen: Tap to close</div>
                                </div>
                                <div className='iconBox'>
                                    <div className="closeButton xpanFont">xpan</div>
                                    <div className="helpFont helpFontPosition">Located Top Left of GroceryList: Tap to expand groceryList to full screen ... DoubleTap back to start size</div>
                                </div>
                                <div className='iconBox'>
                                    <h2 className="groceryListTitle iconPushRight">GroceryList</h2>
                                    <div className="helpFont helpFontPosition">Located in GroceryList: Tap to filter recipes based off grocery list</div>
                                </div>
                                <div className='iconBox'>
                                    <img onDoubleClick={() => setClickedCart(false)} onClick={() => setClickedCart(true)} className={clickedCart?"groceryListFilterOn iconPushRight":"groceryListFilter iconPushRight"} alt="orangeCart" src={orangeCart} />
                                    <div className="helpFont helpFontPosition">Located on MainPage: Tap to filter recipes based off grocery list ... DoubleTap to unfilter</div>
                                </div>
                                <div className='iconBox'>
                                    <li onClick={() => plateClicked()} title="American" alt="ethnicFilter" className={clickedPlate?'plateON content iconPushRight':"plateOFF content"}>American</li>
                                    <div className="helpFont helpFontPosition">Located on MainPage: Tap to filter recipes based off ethnicity ... Tap Again to unfilter</div>
                                </div>
                                <div className='iconBox'>
                                    <img className="calculatorHelp iconPushRight" alt="calculator" src={calculator}/>
                                    <div className="helpFont helpFontPosition">Located inside Recipe: Tap to use calculator at bottom of screen</div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    );
}

export default HelpCenter;