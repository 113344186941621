import React from  'react';
import '../GroceryList/groceryList.css';
import '../../Styles/nomsstyle2.css';
import '../Cookbook/cookbook.css';
import groceryListSymbol from '../Assets/Images/groceryListOrangeCart.png';



const MealPlanner = ({ closeMealPrepNav, groceryListOpen, openNav }) => {
    return(
        <>
        {groceryListOpen?
        <div id="myMealPrepSidenav" className="sidenavCookBook">
            <div className="closeBtnPosition closeButton" onClick={closeMealPrepNav}>&times;</div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div className='cookBookTitle'>MealPrep</div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'powderblue'}}>
                    <h3>Coming With Next Update!</h3>
                </div>
        </div>
        :
        <div id="myMealPrepSidenav" className="sidenavCookBook">
            <div id="openNavButton" onClick={openNav} className='groceryButtonContainer groceryListBtn'>
                    <div className='openGroceryDashes'>
                        &#9776;
                    </div>
                    <img 
                    alt="groceryListButton"
                    className="groceryListButton"
                    src={groceryListSymbol}/>
            </div>
            <div className="closeBtnPosition closeButton" onClick={closeMealPrepNav}>&times;</div>
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <div className='cookBookTitle'>MealPrep</div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'powderblue', height: '50vh'}}>
                    <h3>Coming With Next Update!</h3>
                </div>
        </div>
        }   
        </>
    );
}

export default MealPlanner;