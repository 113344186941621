import React from 'react';
import './signin.css';


const SigninHeader = ({loginBtn, switchSignInScreen, signUpClicked}) => {
    
    const transitionTitle = (e) => {
        switchSignInScreen(e);

        const titleTransition = document.getElementById('titleTransition');
        titleTransition.classList.add("loginFade");
        setTimeout(() => {
            titleTransition.classList.remove("loginFade");
        }, 500)

        const loginScreen = document.getElementById('logInScreen');
        const signupScreen = document.getElementById('signUpScreen');
        if(e.target.title === 'signUp'){
            loginScreen.className = " signUpFade";
            setTimeout(() => {
                loginScreen.classList.remove("signUpFade");
            }, 500)
        }else if (e.target.title === 'logIn'){
            signupScreen.className = " signUpFade";
            setTimeout(() => {
                signupScreen.classList.remove("signUpFade");
            }, 500)
        }
    }

    return (
        <>
            <div className={loginBtn === 'logIn'?'loginPageTitle':'loginPageTitleError'}>
                {/* left-button => Login Page */}
                <div title="logIn" onClick={(e) => transitionTitle(e)}  className='signUpBtn'>
                    logIn 
                </div>
                {/* Center-Area => Title of Page */}
                <div id="titleTransition" style={{paddingRight: '3rem', paddingLeft: '3rem'}}>
                    {signUpClicked?'Sign Up':'Log In'}
                </div>
                {/* right-button => Signup Page */}
                <div title="signUp" onClick={(e) => transitionTitle(e)} className='signUpBtn'>
                    signUp
                </div>
            </div>
        </>
    );
}

export default SigninHeader;