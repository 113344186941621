import React from 'react';
import '../Styles/nomsstyle2.css';
import orangeCart from './Assets/Images/groceryListOrangeCart.png'

const Searchbar = ({searchbar, groceryListFilterOn, groceryListFilter, searching, addToLunchbox, turnFilterOff}) => {
   const keyboardEvents = (e) => {
        if(e.code === 'Enter'){
            addToLunchbox();
          }
      }
    return (
        <div className="searchGrid">
                <img onClick={groceryListFilter} onDoubleClick={turnFilterOff} className={groceryListFilterOn?"groceryListFilterOn":"groceryListFilter"} src={orangeCart} alt="groceryListFilter"/>
    
                <input onKeyDown={(e) => keyboardEvents(e)} onChange={searching} type="text" placeholder="feed me ingredients..." value={searchbar} className="input centerInputText"/>
  
                <div onClick={addToLunchbox} className="feedButton">Feed Me</div>
        </div>
    );
}

export default Searchbar;