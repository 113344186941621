import React from 'react';
import nomsLogo from './Assets/Images/NomsLogo.png';
import '../Styles/nomsstyle2.css';
import '../Styles/scroll.css';

const Navigation = ({ethnicFilter, ethnicTitle, isSignedIn}) => {
    return (
        <div>
            <img src={nomsLogo} className={isSignedIn?"nomsLogoON":"nomsLogoOFF"} alt="nomsLogo"/>
            <div className='scrollHorizontal'>
                <ul className="main-nav contentWrapper plateShadow">
                    <li onClick={ethnicFilter} title="American" alt="ethnicFilter" className={ethnicTitle==='American'?'plateON content':"plateOFF content"}>American</li>
                    <li onClick={ethnicFilter} title="African" alt="ethnicFilter" className={ethnicTitle==='African'?'plateON content':"plateOFF content"}>African</li>
                    <li onClick={ethnicFilter} title="British" alt="ethnicFilter" className={ethnicTitle==='British'?'plateON content':"plateOFF content"}>British</li>
                    <li onClick={ethnicFilter} title="Cajun" alt="ethnicFilter" className={ethnicTitle==='Cajun'?'plateON content':"plateOFF content"}>Cajun</li>
                    <li onClick={ethnicFilter} title="Caribbean" alt="ethnicFilter" className={ethnicTitle==='Caribbean'?'plateON content':"plateOFF content"}>Caribbean</li>
                    <li onClick={ethnicFilter} title="Chinese" alt="ethnicFilter" className={ethnicTitle==='Chinese'?'plateON content':"plateOFF content"}>Chinese</li>
                    <li onClick={ethnicFilter} title="Eastern European" alt="ethnicFilter" className={ethnicTitle==='Eastern European'?'plateON content':"plateOFF content"}><div>Eastern</div><div>European</div></li>
                    <li onClick={ethnicFilter} title="European" alt="ethnicFilter" className={ethnicTitle==='European'?'plateON content':"plateOFF content"}>European</li>
                    <li onClick={ethnicFilter} title="French" alt="ethnicFilter" className={ethnicTitle==='French'?'plateON content':"plateOFF content"}>French</li>
                    <li onClick={ethnicFilter} title="German" alt="ethnicFilter" className={ethnicTitle==='German'?'plateON content':"plateOFF content"}>German</li>
                    <li onClick={ethnicFilter} title="Greek" alt="ethnicFilter" className={ethnicTitle==='Greek'?'plateON content':"plateOFF content"}>Greek</li>
                    <li onClick={ethnicFilter} title="Indian" alt="ethnicFilter" className={ethnicTitle==='Indian'?'plateON content':"plateOFF content"}>Indian</li>
                    <li onClick={ethnicFilter} title="Irish" alt="ethnicFilter" className={ethnicTitle==='Irish'?'plateON content':"plateOFF content"}>Irish</li>
                    <li onClick={ethnicFilter} title="Italian" alt="ethnicFilter" className={ethnicTitle==='Italian'?'plateON content':"plateOFF content"}>Italian</li>
                    <li onClick={ethnicFilter} title="Japanese" alt="ethnicFilter" className={ethnicTitle==='Japanese'?'plateON content':"plateOFF content"}>Japanese</li>
                    <li onClick={ethnicFilter} title="Jewish" alt="ethnicFilter" className={ethnicTitle==='Jewish'?'plateON content':"plateOFF content"}>Jewish</li>
                    <li onClick={ethnicFilter} title="Korean" alt="ethnicFilter" className={ethnicTitle==='Korean'?'plateON content':"plateOFF content"}>Korean</li>
                    <li onClick={ethnicFilter} title="Latin American" alt="ethnicFilter" className={ethnicTitle==='Latin American'?'plateON content':"plateOFF content"}><div>Latin</div><div>American</div></li>
                    <li onClick={ethnicFilter} title="Mediterranean" alt="ethnicFilter" className={ethnicTitle==='Mediterranean'?'plateON content':"plateOFF content"}><div>Mediter-</div><div>ranean</div></li>
                    <li onClick={ethnicFilter} title="Mexican" alt="ethnicFilter" className={ethnicTitle==='Mexican'?'plateON content':"plateOFF content"}>Mexican</li>
                    <li onClick={ethnicFilter} title="Middle Eastern" alt="ethnicFilter" className={ethnicTitle==='Middle Eastern'?'plateON content':"plateOFF content"}><div>Middle</div><div>Eastern</div></li>
                    <li onClick={ethnicFilter} title="Nordic" alt="ethnicFilter" className={ethnicTitle==='Nordic'?'plateON content':"plateOFF content"}>Nordic</li>
                    <li onClick={ethnicFilter} title="Southern" alt="ethnicFilter" className={ethnicTitle==='Southern'?'plateON content':"plateOFF content"}>Southern</li>
                    <li onClick={ethnicFilter} title="Spanish" alt="ethnicFilter" className={ethnicTitle==='Spanish'?'plateON content':"plateOFF content"}>Spanish</li>
                    <li onClick={ethnicFilter} title="Thai" alt="ethnicFilter" className={ethnicTitle==='Thai'?'plateON content':"plateOFF content"}>Thai</li>
                    <li onClick={ethnicFilter} title="Vietnamese" alt="ethnicFilter" className={ethnicTitle==='Vietnamese'?'plateON content':"plateOFF content"}>Vietnam</li>
                </ul>
            </div>
        </div>
    );
}

export default Navigation;