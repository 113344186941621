import React from 'react';
import './Recipes/recipe.css';

export default function Loading () {
    return(
        <div className='loadingContainer'>
            <div key='l0adingKey' className='loadingFont loadingFontEffect'>loading</div>
            <div style={{display: 'flex'}}>
                <div key='bubble1' className='loadingBubbles loadingE1'>.</div>
                <div key='bubble2' className='loadingBubbles loadingE2'>.</div>
                <div key='bubble3' className='loadingBubbles loadingE3'>.</div>
            </div>
        </div>    
    );
}
