import React, { Component } from 'react';
import Navigation from './Navigation.js';
import Searchbar from './Searchbar.js';
import RecipeCard from './Recipes/RecipeCard.js';
import GroceryList from './GroceryList/GroceryList.js';
import Signin from '../Components/SignIn/Signin';

import LunchBox from './Lunchbox/LunchBox';
// import NomsTimeHeader from './NomsTime.js';
import RecipeDetails from './Recipes/RecipeDetails.js';
import Results from './Footer/Results.js';

import '../Styles/nomsstyle2.css';
import './GroceryList/groceryList.css';
import Cookbook from './Cookbook/Cookbook.js';
import MealPlanner from './MealPlanner/MealPlanner.js';
import HelpCenter from './HelpCenter/HelpCenter.js';
import outOfRequestsEmoji from './Assets/Images/outOfApiRequestEmoji.png';
import APItimeout from './APItimeout';

class MainPage extends Component {
  constructor() {
    super();
    this.state = {
      ingAmoList: [],
      groceryListOpen: false,
      searchbar: '',
      searchbarGrocery: '',
      groceryList: [],
      lunchbox: [],
      recipeBook: [],
      recipeIngredients: [],
      savedRecipes: [],
      currentRecipe: [],
      recipeOpen: false,
      groceryListExpand: false,
      recipeInstructions: [],
      ingredientAmountPerServing: [],
      recipeID: '',
      recipeTitle: '',
      recipeImage: '',
      readyInMinutes: 0,
      servings: 0,
      adjustedServing: 0,
      pricePerServing: 0,
      ethnicTitle: '',
      totalResults: 0,
      resultsRemaining: 0,
      ethnicFilterOn: false,
      groceryListFilterOn: false,
      savFav: false,
      clicked: '',
      crossedOff: [], 
      page: 10, 
      didExpand: false,
      openedSavedRecipes: false,

      user: {
        id: '',
        email: '',
        cookbook: [],
        groceryList: [],
        joined: '',
        signedIn: false
      }
    }
  }

// userProfile /////////////////
  loaduser = (data) => {
    if(data.cookbook === null){
      this.setState({
        user: {
          id: data.id,
          email: data.email,
          cookbook: this.state.user.cookbook,
          groceryList: data.groceryList,
          joined: data.joined,
          signedIn: true
        }
      })
    }else if (data.groceryList === null){
      this.setState({
        user: {
          id: data.id,
          email: data.email,
          cookbook: data.cookbook,
          groceryList: this.state.user.groceryList,
          joined: data.joined,
          signedIn: true
        }
      })
    }else if (data.groceryList === null && data.cookbook === null){
      this.setState({
        user: {
          id: data.id,
          email: data.email,
          cookbook: this.state.user.cookbook,
          groceryList: this.state.user.groceryList,
          joined: data.joined,
          signedIn: true
        }
      })
    }else{
      this.setState({
        user: {
          id: data.id,
          email: data.email,
          cookbook: data.cookbook,
          groceryList: data.grocerylist,
          joined: data.joined,
          signedIn: true
        }
      })
    }
  }

  updateResults = (data) => {
    console.log(data.results, ' <= login recipe request');
    if(this.state.pricePerServing === 'NaN'){
      this.setState({ pricePerServing: 0})
    }
    if(data.results.length < 1){
      this.setState({recipeBook: [{title: 'Out of API Requests', image: {outOfRequestsEmoji}}]});
      this.setState({ totalResults: 0 });
      this.setState({ resultsRemaining: 0 })
    }else{
      this.setState({recipeBook: data.results});
      this.setState({ totalResults: data.totalResults });
      this.setState({ resultsRemaining: data.totalResults });
    }
  }

// Grocery List Fuctionality 
  crossOff = (event) => {
    if(this.state.user.signedIn){
      const filteredList = this.state.user.groceryList.filter(ingredient => ingredient.name === event.target.id)
  
      let groceryListItem = document.getElementById(filteredList[0].name);
  
      if(event.target.id !== filteredList && (groceryListItem.style.getPropertyValue("text-decoration") !== "line-through")){
        groceryListItem.style.setProperty("text-decoration", "line-through");
        groceryListItem.style.setProperty("text-decoration-color", "tomato");
        groceryListItem.style.setProperty("text-decoration-thickness", "0.5vmin");
  
      }else{
        groceryListItem.style.setProperty("text-decoration", "none");
      }

    }else{
      const filteredList = this.state.groceryList.filter(ingredient => ingredient === event.target.id)
      let groceryListItem = document.getElementById(filteredList);
  
      if(event.target.id !== filteredList && (groceryListItem.style.getPropertyValue("text-decoration") !== "line-through")){
        groceryListItem.style.setProperty("text-decoration", "line-through");
        groceryListItem.style.setProperty("text-decoration-color", "tomato");
        groceryListItem.style.setProperty("text-decoration-thickness", "0.5vmin");
  
      }else{
        groceryListItem.style.setProperty("text-decoration", "none");
      }
    }
}

powerSwitch = () => {
  if(this.state.user.signedIn){
    this.setState({ lunchbox: []});
    this.setState({ recipeBook: []});
    this.setState({ recipeIngredients: []});
    this.setState({ savedRecipes: []});
    this.setState({ currentRecipe: []});
    this.setState({ ethnicFilterOn: false});
    this.setState({ ethnicTitle: ''});
    this.setState({ totalResults: 0});
    this.setState({
      user: {
        id: '',
        email: '',
        cookbook: [],
        groceryList: [],
        joined: '',
        signedIn: false
      }
    })
    document.getElementById('signInID').style.height = 'auto';
    document.getElementById('signInID').style.opacity = '1';
    document.getElementById('signInID').style.zIndex = '3000';
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.zIndex = "0";  
    document.getElementById("myMealPrepSidenav").style.width = "0";
    document.getElementById("myMealPrepSidenav").style.zIndex = "0";
    document.getElementById("myHelpCenterNav").style.width = "0";
    document.getElementById("myHelpCenterNav").style.zIndex = "0";
    document.getElementById("myCookBookSidenav").style.width = "0";        
    document.getElementById("myCookBookSidenav").style.zIndex = "0";
  }
}

  openNav = () => {
    this.setState({didExpand: false});
    document.getElementById("mySidenav").style.width = "25vmax";
    document.getElementById("mySidenav").style.zIndex = "1000";  
    document.getElementById("openNavButton").style.zIndex = '-2';
    this.setState({ groceryListOpen: true });
}

  expandNav = () => {
    this.setState({didExpand: true});
    document.getElementById("mySidenav").style.width = "100%";
    document.getElementById("myMealPrepSidenav").style.width = "0";
    document.getElementById("myCookBookSidenav").style.width = "0";
}

  compressNav = () => {
    this.setState({didExpand: false});
    document.getElementById("mySidenav").style.width = "25vmax";
}

  closeNav = () => {
    this.setState({didExpand: false});
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.zIndex = "0"; 
    document.getElementById("openNavButton").style.zIndex = '100';;
    this.setState({ groceryListOpen: false });
}

  openCookBookNav = () => {
    this.setState({didExpand: false});
    document.getElementById("myMealPrepSidenav").style.width = "0";
    document.getElementById("myHelpCenterNav").style.width = "0";
    document.getElementById("myCookBookSidenav").style.width = "100vw";        
    document.getElementById("myCookBookSidenav").style.zIndex = "2";
    document.getElementById("mySidenav").style.width = "25vmax";
    document.getElementById("mySidenav").style.opacity = "0.98";
    document.getElementById("mySidenav").style.zIndex = "1000";
    document.getElementById("mySidenavRecipe").style.height = "0";
    document.getElementById("mySidenavRecipe").style.opacity = "0";
}

  closeCookBookNav = () => {
    document.getElementById("myCookBookSidenav").style.width = "0";
}

  openMealPrepkNav = () => {
    this.setState({didExpand: false});
    document.getElementById("myCookBookSidenav").style.width = "0";
    document.getElementById("myHelpCenterNav").style.width = "0";
    document.getElementById("myMealPrepSidenav").style.width = "100vw";
    document.getElementById("myMealPrepSidenav").style.zIndex = "1";
    document.getElementById("mySidenav").style.width = "25vmax";
    document.getElementById("mySidenavRecipe").style.height = "0";
    document.getElementById("mySidenavRecipe").style.opacity = "0";
}

  closeMealPrepNav = () => {
    document.getElementById("myMealPrepSidenav").style.width = "0";
}

// Grocery List functionality

// Help ?
  openHelp = () => {
    this.setState({didExpand: false});
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("myMealPrepSidenav").style.width = "0";
    document.getElementById("myCookBookSidenav").style.width = "0";
    document.getElementById("myHelpCenterNav").style.width = "100%"; 
    document.getElementById("myHelpCenterNav").style.zIndex = "3"; 
    this.setState({ groceryListOpen: false }); 
}

 closeHelpNav = () => {
  document.getElementById("myHelpCenterNav").style.width = "0"; 
}

  turnFilterOff = (event) => {
    if (event.target.alt === 'groceryListFilter' && !this.state.ethnicFilterOn) {
      this.setState({ groceryListFilterOn: false });
      fetch(
        `https://api.spoonacular.com/recipes/random?number=100&apiKey=589f2dc10359411092bf6266b33c169a`
      )
        .then(response => response.json())
        .then(data => this.setState({ recipeBook: data.recipes }))
        .catch(() => {
          console.log("error fetching recipes");
        })
    } else if (event.target.alt === 'groceryListFilter' && this.state.ethnicFilterOn) {
      this.setState({ groceryListFilterOn: false });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&cuisine=' + this.state.ethnicTitle;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results })
          this.setState({ totalResults: data.totalResults })
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes")
        })
    }
  }

  adjustServings = (event) => {
    if(event.target.title === 'increase'){
      this.setState({ servings: this.state.servings + 1});
      this.setState({ adjustedServing: this.state.adjustedServing + 1});
    }else if(event.target.title === 'decrease'){
      this.setState({ servings: this.state.servings - 1});
      this.setState({ adjustedServing: this.state.adjustedServing - 1});
    }
  }

  adjustRecipePrice = () => {
    if (this.state.openedSavedRecipes){
      let costPerServing = this.state.currentRecipe.pricePerServing / this.state.currentRecipe.servings;
      this.setState({ pricePerServing: costPerServing * this.state.servings});
    }else{
      this.setState({ pricePerServing: ((this.state.currentRecipe.pricePerServing / 100) * this.state.servings)});
    }
  }
 
  adjustServingAmount = () => {
    this.setState({ingredientAmountPerServing: this.state.ingredientAmount.map(amount => amount * this.state.servings)})
  }

  lunchboxFilter = () => {
    this.setState({page: 10});
    console.log('lunchboxFilter => ', this.state.user.groceryList);
    let groceryListItem = this.state.user.groceryList.map(item => item.name);
    console.log('groceryListItem => ', groceryListItem);
    console.log('lunchboxinfilter => ', this.state.lunchbox);
    
    if (this.state.lunchbox.length > 0 && !this.state.groceryListFilterOn && !this.state.ethnicFilterOn) {
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults });
        })
        .catch(() => {
          console.log("error fetching recipes based off lunchbox search")
        })
    } else if (this.state.lunchbox.length > 0 && this.state.groceryListFilterOn && !this.state.ethnicFilterOn) {
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox + "," + this.state.user.groceryList[0].name;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off lunchbox search")
        })
    } else if (this.state.lunchbox.length > 0 && !this.state.groceryListFilterOn && this.state.ethnicFilterOn) {
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off lunchbox search")
        })
    } else if (this.state.lunchbox.length > 0 && this.state.groceryListFilterOn && this.state.ethnicFilterOn) {
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox + "," + this.state.user.groceryList[0].name;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off lunchbox search")
        })
    }
  }

  groceryListFilter = () => {
    console.log('groceryListFilterClicked!');
    console.log('groceryListFilter1=> ',this.state.user.groceryList[0].length);
    console.log('groceryListFilter2 => ',this.state.user.groceryList[0]);
    console.log('groceryListFilter3 => ',this.state.user.groceryList.length);
    if(this.state.user.groceryList.length > 0){
      this.setState({page: 10});
      if (!this.state.ethnicFilterOn && this.state.lunchbox.length < 1) {
        this.setState({ groceryListFilterOn: true });
        let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.user.groceryList[0].name;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.setState({ recipeBook: data.results });
            this.setState({ totalResults: data.totalResults });
            this.setState({ resultsRemaining: data.totalResults })
          })
          .catch(() => {
            console.log("error fetching recipes based off groceryList search")
          })
      } else if (!this.state.ethnicFilterOn && this.state.lunchbox.length > 0) {
        this.setState({ groceryListFilterOn: true });
        let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.user.groceryList[0].name + "," + this.state.lunchbox;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.setState({ recipeBook: data.results });
            this.setState({ totalResults: data.totalResults });
            this.setState({ resultsRemaining: data.totalResults })
          })
          .catch(() => {
            console.log("error fetching recipes based off groceryList search")
          })
      } else if (this.state.ethnicFilterOn && this.state.lunchbox.length < 1) {
        this.setState({ groceryListFilterOn: true });
        let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.user.groceryList[0].name + '&offset=0';
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.setState({ recipeBook: data.results });
            this.setState({ totalResults: data.totalResults });
            this.setState({ resultsRemaining: data.totalResults })
          })
          .catch(() => {
            console.log("error fetching recipes based off groceryList search")
          })
      } else if (this.state.ethnicFilterOn && this.state.lunchbox.length > 0) {
        this.setState({ groceryListFilterOn: true });
        let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.user.groceryList[0].name + "," + this.state.lunchbox;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.setState({ recipeBook: data.results });
            this.setState({ totalResults: data.totalResults });
            this.setState({ resultsRemaining: data.totalResults })
          })
          .catch(() => {
            console.log("error fetching recipes based off groceryList search")
          })
      }
    }
  }

  ethnicFilter = (event) => {
    this.setState({page: 10});
    if (this.state.ethnicTitle !== event.target.title && !this.state.groceryListFilterOn && this.state.lunchbox.length < 1) {
      this.setState({ ethnicFilterOn: true });
      this.setState({ ethnicTitle: event.target.title });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&cuisine=' + event.target.title;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results })
          this.setState({ totalResults: data.totalResults })
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle !== event.target.title && !this.state.groceryListFilterOn && this.state.lunchbox.length > 0) {
      this.setState({ ethnicFilterOn: true });
      this.setState({ ethnicTitle: event.target.title });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + event.target.title + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle !== event.target.title && this.state.groceryListFilterOn && this.state.lunchbox.length < 1) {
      this.setState({ ethnicFilterOn: true });
      this.setState({ ethnicTitle: event.target.title });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + event.target.title + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle !== event.target.title && this.state.groceryListFilterOn && this.state.lunchbox.length > 0) {
      this.setState({ ethnicFilterOn: true });
      this.setState({ ethnicTitle: event.target.title });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + event.target.title + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList + "," + this.state.lunchbox;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle === event.target.title && this.state.groceryListFilterOn && this.state.lunchbox.length < 1) {
      this.setState({ ethnicFilterOn: false });
      this.setState({ ethnicTitle: "" });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle === event.target.title && this.state.groceryListFilterOn && this.state.lunchbox.length > 0) {
      this.setState({ ethnicFilterOn: false });
      this.setState({ ethnicTitle: "" });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList + "," + this.state.lunchbox;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle === event.target.title && !this.state.groceryListFilterOn && this.state.lunchbox.length > 0) {
      this.setState({ ethnicFilterOn: false });
      this.setState({ ethnicTitle: "" });
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: data.results });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    } else if (this.state.ethnicTitle === event.target.title && !this.state.groceryListFilterOn && this.state.lunchbox.length < 1) {
      this.setState({ ethnicFilterOn: false });
      this.setState({ ethnicTitle: "" });
      fetch(
        `https://api.spoonacular.com/recipes/random?number=100&apiKey=589f2dc10359411092bf6266b33c169a`
      )
        .then(response => response.json())
        .then(data => this.setState({ recipeBook: data.recipes }))
        .catch(() => {
          console.log("error fetching recipes based off ethnicFilter search")
        })
    }
  }

  addToList = (event) => {
    let newItem = {
      name: event.target.title,
      unit: 'Ea',
      amount: 0
    }
    this.setState(prevState =>({
      user: {...prevState.user, groceryList: [...this.state.user.groceryList, newItem]}
    }))
    fetch('https://protected-temple-53634.herokuapp.com/groceryList/', {
          method: 'put', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
              id: this.state.user.id,
              listUpdated: [...this.state.user.groceryList, newItem]
          })
        }).then(res => res.json())
        .catch(err => console.log('something went wrong! please try again...'))
  }

  addRecipeIngredientsToList = () => {
    const newList = this.state.recipeIngredients.map(ingredient => ingredient.name)
    this.setState({ groceryList: [...this.state.groceryList, newList.map(ingredient => ingredient)] });
  }

  savedRecipeOpened = (event) => {
    // RECIPE DETAIL OVERLAY OPENED/////
    document.getElementById("myCookBookSidenav").style.width = "0";
    this.setState({ recipeTitle: event.target.title });
    this.setState({recipeID: event.target.alt});
    this.setState({ openedSavedRecipes: true });
    document.getElementById("mySidenavRecipe").style.height = "85vh";
    document.getElementById("mySidenavRecipe").style.zIndex = "1";
    document.getElementById("mySidenavRecipe").style.opacity = "0.99";
    // RECIPE DETAILS LOADED/////
    let instructions = this.state.user.cookbook.filter(recipe => recipe.id === event.target.alt);
    this.setState({ currentRecipe: instructions[0]});
    this.setState({ recipeInstructions: instructions[0].instructions.map(steps => steps) });
    this.setState({ recipeImage: instructions[0].image });
    this.setState({ ingAmoList: instructions[0].ingAmoList})
    this.setState({ recipeIngredients: instructions[0].ingredients });
    this.setState({ readyInMinutes: instructions[0].readyInMinutes});
    this.setState({ servings: instructions[0].servings});
    this.setState({ pricePerServing: instructions[0].pricePerServing });
    this.setState({ savFav: instructions[0].saved });
    this.setState({ ingredientAmountPerServing: instructions[0].ingredientAmountPerServing })
  }

  recipeOpened = (event) => {
    this.setState({openedSavedRecipes: false});
    document.getElementById("myCookBookSidenav").style.width = "0";
    this.setState({ recipeTitle: event.target.title });
    this.setState({recipeID: event.target.alt});
    document.getElementById("mySidenavRecipe").style.height = "85vh";
    document.getElementById("mySidenavRecipe").style.zIndex = "1";
    document.getElementById("mySidenavRecipe").style.opacity = "0.99";

    let instructions = this.state.recipeBook.filter(recipe => recipe.id === Number(event.target.alt));
    this.setState({ currentRecipe: instructions[0]});
    
    this.setState({ recipeImage: instructions[0].image });
    let ingredients = instructions[0].extendedIngredients.map(ingredient => ingredient);
    this.setState({ recipeIngredients: ingredients });

    this.setState({ ingredientAmountPerServing:  ingredients.map(ingredient => ingredient.measures.us.amount / instructions[0].servings)});
    
    this.setState({ ingAmoList: [ingredients.map(ingredient => ingredient.measures.us.amount / instructions[0].servings), ingredients]})
    this.setState({ readyInMinutes: instructions[0].readyInMinutes});
    this.setState({ servings: instructions[0].servings});
    this.setState({ adjustedServing: instructions[0].servings});
    this.setState({ pricePerServing: ((instructions[0].pricePerServing / 100) * instructions[0].servings)});

    if(instructions[0].analyzedInstructions[0].length > 0){
      this.setState({recipeInstructions: ['Cooking Steps Not Provided...']});
    }else{
      this.setState({ recipeInstructions: instructions[0].analyzedInstructions[0].steps.map(steps => steps.step) });
    }


    const newCookbook = this.state.savedRecipes.filter(recipe => Number(recipe.id) === Number(event.target.alt))
      if (newCookbook.length>0){
        return this.setState({ savFav: true });
      }else{
        return this.setState({ savFav: false });
      }

    }

  recipeClosed = () => {
    document.getElementById("mySidenavRecipe").style.height = "0";
    document.getElementById("mySidenavRecipe").style.opacity = "0"; 
  }

  searching = (event) => {
    this.setState({ searchbar: event.target.value });
  }

  searchingGrocery = (event) => {
    this.setState({ searchbarGrocery: event.target.value });
  }

  addToGroceryList = () => {
    if (this.state.searchbarGrocery.length > 1) {
      if(this.state.user.signedIn){
        let item = {
          name: this.state.searchbarGrocery,
          unit: 'Ea',
          amount: 0
        }
        this.setState(prevState => ({
          user: {...prevState.user, groceryList: [...this.state.user.groceryList, item]}
        }))
        this.setState({ searchbarGrocery: '' });
        fetch('https://protected-temple-53634.herokuapp.com/groceryList/', {
          method: 'put', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
              id: this.state.user.id,
              listUpdated: [...this.state.user.groceryList, item]
          })
        }).then(res => res.json())
        .catch(err => console.log('something went wrong! please try again...'))
      }else{
        this.setState({groceryList: [...this.state.groceryList, this.state.searchbarGrocery]})
        this.setState({ searchbarGrocery: '' });
      }
    }
  }

  removeFromGroceryList = (event) => {
    if(this.state.user.signedIn){
      this.setState(prevState => (
        {
          user: {...prevState.user, groceryList: this.state.user.groceryList.filter(
            ingredient => ingredient.name !== event.target.innerText
          )}
        }
      )
      );
      fetch('https://protected-temple-53634.herokuapp.com/groceryList/', {
          method: 'put', 
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({
              id: this.state.user.id,
              listUpdated: this.state.user.groceryList.filter(
                ingredient => ingredient.name !== event.target.innerText
              )
          })
      })
    }else{
      this.setState({groceryList: this.state.groceryList.filter(
        ingredient => ingredient !== event.target.innerText
      )})
    }
  }

  updateGroceryList = (e) => {
  // UNIT CHANGE ///////////////////////////////////////////////////////////
    if(e.target.title === "metric"){
      const filteredList = this.state.user.groceryList.filter(item => {
        if(item.name === e.target.previousSibling.id){
          item.unit = e.target.value
        }
        return item;
      })
      this.setState(prevState =>( {
            user: {...prevState.user, groceryList: filteredList}
          }))

          fetch('https://protected-temple-53634.herokuapp.com/groceryList/', {
            method: 'put', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: this.state.user.id,
                listUpdated: filteredList
            })
          }).then(res => res.json())
          .catch(err => console.log('something went wrong! please try again...'))
    }else if (e.target.parentElement.title === "increaseItemAmount"){
      let ingredient = e.target.parentElement.id.replace("Increase", '');
     
      const filteredList = this.state.user.groceryList.filter(item => {
        if(item.name === ingredient){
          item.amount ++;
        }
        return item;
      })
  
      this.setState(prevState =>( {
            user: {...prevState.user, groceryList: filteredList}
          }))

          fetch('https://protected-temple-53634.herokuapp.com/groceryList/', {
            method: 'put', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: this.state.user.id,
                listUpdated: filteredList
            })
          }).then(res => res.json())
          .catch(err => console.log('something went wrong! please try again...'))
    }else if (e.target.parentElement.title === "decreaseItemAmount"){
      let ingredient = e.target.parentElement.id.replace("Decrease", '');
      
      const filteredList = this.state.user.groceryList.filter(item => {
        if(item.name === ingredient){
          item.amount --;
        }
        return item;
      })
  
      this.setState(prevState =>( {
            user: {...prevState.user, groceryList: filteredList}
          }))

          fetch('https://protected-temple-53634.herokuapp.com/groceryList/', {
            method: 'put', 
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                id: this.state.user.id,
                listUpdated: filteredList
            })
          }).then(res => res.json())
          .catch(err => console.log('something went wrong! please try again...'))
    }
  }

  addToLunchbox = () => {
    if (this.state.searchbar.length > 1) {
      this.setState({ lunchbox: [...this.state.lunchbox, this.state.searchbar] });
      
    }
    this.setState({ searchbar: '' });
  }

  removeFromLunchBox = (event) => {
    this.setState(
      {
        lunchbox: this.state.lunchbox.filter(
          ingredient => ingredient !== event.target.innerText
        )
      }
    );
  }

  save2Fav = () => {
    if (!this.state.savFav){
      let saveRecipe = {
        id: this.state.recipeID,
        saved: !this.state.savFav,
        title: this.state.recipeTitle,
        ethnicTitle: this.state.ethnicTitle,
        image: this.state.recipeImage,
        ingAmoList: this.state.ingAmoList,
        ingredients: this.state.recipeIngredients,
        instructions: this.state.recipeInstructions, 
        readyInMinutes: this.state.readyInMinutes,
        servings: this.state.servings,
        pricePerServing: this.state.pricePerServing,
        ingredientAmountPerServing: this.state.ingredientAmountPerServing

      }
      this.setState({savFav: true});
      this.setState({savedRecipes: [...this.state.savedRecipes, saveRecipe]})
      this.setState(prevState => ({
        user: {...prevState.user, cookbook: [...this.state.user.cookbook, saveRecipe]}
      }))
      fetch('https://protected-temple-53634.herokuapp.com/cookbook', {
                method: 'put', 
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    id: this.state.user.id,
                    cookbook: [...this.state.user.cookbook, saveRecipe]
                })
            })
            .then(res => res.json())
            .catch(err => console.log('ooops! Something went wrong. Please try again...'))
    }else if (this.state.savFav){

      const filteredSaved2 = this.state.user.cookbook.filter(recipe => recipe.id !== this.state.recipeID);
      fetch('https://protected-temple-53634.herokuapp.com/cookbook', {
        method: 'put', 
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            id: this.state.user.id,
            cookbook: filteredSaved2
        })
    })
      .then(res => res.json())
      .catch(() => console.log('error removing recipe from cookbook'))
      this.setState(prevState => ({
        user: {
          ...prevState.user, cookbook: filteredSaved2
        }
      }))
      this.setState({savFav: false});
      const filteredSaved = this.state.savedRecipes.filter(recipe => recipe.id !== this.state.recipeID);
      this.setState({savedRecipes: filteredSaved});
    }
  }

  enterKey = (event) => {
    if (event.key === 13) {
      this.addToLunchbox();
    }
  }

  loadMore = () => {
    console.log('clicked LOAD MORE!');
// ALL Filters (OFF)
    if (!this.state.ethnicFilterOn&&!this.state.groceryListFilterOn&&this.state.lunchbox.length < 1){
      fetch(
        `https://api.spoonacular.com/recipes/random?number=100&apiKey=589f2dc10359411092bf6266b33c169a&offset=`+this.state.page 
      )
        .then(response => response.json())
        .then(data =>  this.setState({recipeBook: [...this.state.recipeBook, ...data.recipes]}))
        .catch(() => {
          console.log("error loading more")
        })
        this.setState({page: this.state.page + 10});
// ETHNIC FILTER (ON) ONLY
    }else if (this.state.ethnicFilterOn&&!this.state.groceryListFilterOn&&this.state.lunchbox.length < 1){
      
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&cuisine=' + this.state.ethnicTitle + '&offset='+ this.state.page;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] })
        })
        .catch(() => {
          console.log("error loading more")
        })
        this.setState({page: this.state.page + 10});
// ETHNIC FILTER (ON) && GROCERY LIST (OFF) && lunchbox (ON)
    }else if (this.state.ethnicFilterOn&&!this.state.groceryListFilterOn&&this.state.lunchbox.length > 0){
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox + '&offset=' + this.state.page;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error loading more")
        })
        this.setState({page: this.state.page + 10});
// ETHNIC FILTER (ON) && GROCERY LIST FILTER (ON) && LunchBox (OFF)
    }else if (this.state.ethnicFilterOn&&this.state.groceryListFilterOn&&this.state.lunchbox.length < 1){
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList + '&offset=' + this.state.page;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error loading more")
        })
        this.setState({page: this.state.page + 10});
// Ethnic Filter (ON) && GroceryList (ON) && lunchbox (ON)
    }else if (this.state.ethnicFilterOn&&this.state.groceryListFilterOn&&this.state.lunchbox.length > 0){
      let url = 'https://api.spoonacular.com/recipes/complexSearch?cuisine=' + this.state.ethnicTitle + '&fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList + "," + this.state.lunchbox + '&offset=' + this.state.page;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error loading more")
        })
        this.setState({page: this.state.page + 10});
// Ethnic Filter (OFF) && GroceryList (ON) && lunchbox (ON)
    }else if (!this.state.ethnicFilterOn&&this.state.groceryListFilterOn&&this.state.lunchbox.length > 0){
      let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList + "," + this.state.lunchbox + '&offset=' + this.state.page;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] });
          this.setState({ totalResults: data.totalResults });
          this.setState({ resultsRemaining: data.totalResults })
        })
        .catch(() => {
          console.log("error loading more")
        })
        this.setState({page: this.state.page + 10});
// Ethnic Filter (OFF) && GroceryList (OFF) && lunchbox (ON)
      }else if (!this.state.ethnicFilterOn&&!this.state.groceryListFilterOn&&this.state.lunchbox.length > 0){
        let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.lunchbox + '&offset=' + this.state.page;
        fetch(url)
          .then(response => response.json())
          .then(data => {
            this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] });
            this.setState({ totalResults: data.totalResults });
            this.setState({ resultsRemaining: data.totalResults })
          })
          .catch(() => {
            console.log("error loading more")
          })
          this.setState({page: this.state.page + 10});
// Ethnic Filter (OFF) && GroceryList (ON) && lunchbox (OFF)
        }else if (!this.state.ethnicFilterOn&&this.state.groceryListFilterOn&&this.state.lunchbox.length < 1){
          let url = 'https://api.spoonacular.com/recipes/complexSearch?fillIngredients=true&addRecipeInformation=true&number=100&apiKey=589f2dc10359411092bf6266b33c169a&includeIngredients=' + this.state.groceryList + '&offset=' + this.state.page;
          fetch(url)
            .then(response => response.json())
            .then(data => {
              this.setState({ recipeBook: [...this.state.recipeBook, ...data.results] });
              this.setState({ totalResults: data.totalResults });
              this.setState({ resultsRemaining: data.totalResults })
            })
            .catch(() => {
              console.log("error loading more")
            })
            this.setState({page: this.state.page + 10});
          }
  }
  
  render() { 
    return (
      <>
        <Signin cookbook={this.state.user.cookbook} powerSwitch={this.powerSwitch} isSignedIn={this.state.user.signedIn} updateResults={this.updateResults} groceryList={this.state.user.groceryList} loaduser={this.loaduser} signedIn={this.signedIn}/>
          {/* Recipe Details Slide Up From Bottom Screen After Pressing Recipe Image */}
        <div className={this.state.user.signedIn?"poweredON":"poweredOFF"}>
          <RecipeDetails ingAmoList={this.state.ingAmoList} ingredientAmountPerServing={this.state.ingredientAmountPerServing} adjustServingAmount={this.adjustServingAmount} quantityToList={this.quantityToList} adjustedServing={this.state.adjustedServing} adjustRecipePrice={this.adjustRecipePrice} adjustServings={this.adjustServings} servings={this.state.servings} pricePerServing={this.state.pricePerServing} readyInMinutes={this.state.readyInMinutes} save2Fav={this.save2Fav} savFav={this.state.savFav} addRecipeIngredientsToList={this.addRecipeIngredientsToList} addToList={this.addToList} recipeImage={this.state.recipeImage} recipeIngredients={this.state.recipeIngredients} recipeInstructions={this.state.recipeInstructions} recipeBook={this.state.recipeBook} recipeTitle={this.state.recipeTitle} recipeClosed={this.recipeClosed} />
          {/* Grocery List Slides Out From Left Screen After Pressing Top Left Cart Button */}
          <GroceryList updateGroceryList={this.updateGroceryList} isSignedIn={this.state.user.signedIn} groceryList2={this.state.user.groceryList} didExpand={this.state.didExpand} crossOff={this.crossOff} openHelp={this.openHelp} closeHelpNav={this.closeHelpNav} openNav={this.openNav} expandNav={this.expandNav} compressNav={this.compressNav} closeNav={this.closeNav} openCookBookNav={this.openCookBookNav} closeCookBookNav={this.closeCookBookNav} openMealPrepkNav={this.openMealPrepkNav} closeMealPrepNav={this.closeMealPrepNav} recipeDidOpen={this.state.recipeOpen} recipeOpened={this.recipeOpened} savedRecipes={this.state.savedRecipes} searchbarGrocery={this.state.searchbarGrocery} groceryListFilter={this.groceryListFilter} groceryListExpand={this.state.groceryListExpand} groceryList={this.state.groceryList} addToGroceryList={this.addToGroceryList} removeFromGroceryList={this.removeFromGroceryList} searchingGrocery={this.searchingGrocery} />
          {/* HelpCenter */}
          <HelpCenter powerSwitch={this.powerSwitch} signedIn={this.state.user.signedIn} ethnicTitle={this.state.ethnicTitle} ethnicFilter={this.ethnicFilter} openNav={this.openNav} closeHelpNav={this.closeHelpNav}/>
          {/* Cookbook */}
          <Cookbook isSignedIn={this.state.user.signedIn} savedRecipeOpened={this.savedRecipeOpened} openNav={this.openNav} recipeOpened={this.recipeOpened} savedRecipes={this.state.user.cookbook} savedRecipesOff={this.state.savedRecipes} groceryListOpen={this.state.groceryListOpen} openCookBookNav={this.openCookBookNav} closeCookBookNav={this.closeCookBookNav}/>
          {/* MealPlanner */}
          <MealPlanner openNav={this.openNav} groceryListOpen={this.state.groceryListOpen} closeMealPrepNav={this.closeMealPrepNav}/>
          {/* American /// Asian /// Italian /// Mexican /// filter PlateButtons */}
          <Navigation isSignedIn={this.state.user.signedIn} ethnicTitle={this.state.ethnicTitle} ethnicFilter={this.ethnicFilter} />
          {/* Its Time For Noms '''Header''' */}
          {/* <NomsTimeHeader /> */}
          <Searchbar groceryListFilter={this.groceryListFilter} turnFilterOff={this.turnFilterOff} groceryListFilterOn={this.state.groceryListFilterOn} enterKey={this.enterKey} searchbar={this.state.searchbar} addToLunchbox={this.addToLunchbox} searching={this.searching} />
          {this.state.lunchbox ?
            <div>
              <LunchBox groceryListFilterOn={this.state.groceryListFilterOn} mixedBox={this.state.mixedBox} lunchboxFilter={this.lunchboxFilter} removeFromLunchBox={this.removeFromLunchBox} lunchbox={this.state.lunchbox} />
              <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap'}}>
                {
                  !this.state.recipeBook ?
                  <APItimeout />
                  :
                  <RecipeCard totalResults={this.state.totalResults} isSignedIn={this.state.user.signedIn} loadMore={this.loadMore} recipeBook={this.state.recipeBook} recipeOpened={this.recipeOpened} />
                }            
              </div>
            </div>
            :
            <div style={{ display: 'flex', justifyContent: 'center'}}>
              {!this.state.recipeBook ?
                <APItimeout /> 
                :
                <RecipeCard totalResults={this.state.totalResults} isSignedIn={this.state.user.signedIn} loadMore={this.loadMore} recipeBook={this.state.recipeBook} recipeOpened={this.recipeOpened} /> 
              }
            </div>
          }
          <footer style={{position: 'fixed', bottom: '0', width: '100vw', height: '4vmax', backgroundColor: 'powderblue'}}>          
              <Results openHelp={this.openHelp} totalResults={this.state.totalResults}/>
          </footer>
        </div>
      </>
    );
  }
}

export default MainPage;  