import React from 'react';
import './Recipes/recipe.css';
import apiTimeoutEmoji from './Assets/Images/outOfApiRequestEmoji.png';

export default function APItimeout () {
    return(
        <div className='apiTimeoutContainer'>
            <div key='l0adingKey' className='loadingFont loadingFontEffect' style={{fontFamily: 'Hanalei Fill, cursive'}}>Out Of Requests</div>
            <div style={{display: 'flex'}}>
                <img alt='' src={apiTimeoutEmoji} style={{height: '30vh', width: '40vw'}}/> 
            </div>
        </div>    
    );
}