import React, {useEffect} from 'react';
import './lunchbox.css';

const LunchBox = ({lunchbox, lunchboxFilter, removeFromLunchBox}) => {
    useEffect(() => {
        lunchboxFilter();
    }, [lunchbox, lunchboxFilter])
    return(
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ flexWrap: 'wrap', borderRadius: '10px', width: '70vw', display: 'flex', justifyContent: 'center'}}>
                {lunchbox.map((ingredient, i) => {
                return <div key={i+100} onDoubleClick={removeFromLunchBox} className="lunchboxDelete">{ingredient}</div>;
            })}
            </div>
        </div>
    )
}

export default LunchBox;