import React, { Component } from 'react';
import './calculator.css';
import '../../Styles/nomsstyle2.css';

class Calculator extends Component {
    constructor(){
        super();
        this.state = {
            title: 'amountL',
            amountL: '0',
            amountR: '0',
            metricL: 'Cups',
            metricR: 'Cups'
        }
    }
    
    selectOptionsL = (event) => {
        if(event.target.title === 'metricL'&& ((event.target.value === 'Cups')||(event.target.value === 'Teaspoons')||(event.target.value === 'Tablespoons')||
        (event.target.value === 'Pounds')||(event.target.value === 'Ounces')||(event.target.value === 'Pint')||(event.target.value === 'Quart')||
        (event.target.value === 'Gallons')|| (event.target.value === 'Liters')||(event.target.value === 'milliLiters'))){
            this.setState({metricL: event.target.value});
            this.setState({title: 'amountL'});
        }
    }
    
    selectOptionsR = (event) => {
        if (event.target.title === 'metricR'&&((event.target.value === 'Cups')||(event.target.value === 'Teaspoons')||
        (event.target.value === 'Tablespoons')|| (event.target.value === 'Pounds')||(event.target.value === 'Ounces')||(event.target.value === 'Pint')||
        (event.target.value === 'Quart')||(event.target.value === 'Gallons')||(event.target.value === 'Liters')||(event.target.value === 'milliLiters'))){
            this.setState({ metricR: event.target.value});
            this.setState({title: 'amountR'});
        }
    }

    amountAdjMeasure = () => {
    // CUPS => VARIABLE ///////////////
            if (this.state.title === 'amountL' && ((this.state.metricL === 'Cups' && this.state.metricR === 'Cups')||(this.state.metricL === 'Teaspoons' && this.state.metricR === 'Teaspoons')||(this.state.metricL === 'Tablespoons' && this.state.metricR === 'Tablespoons')||(this.state.metricL === 'Ounces' && this.state.metricR === 'Ounces')||(this.state.metricL === 'Pint' && this.state.metricR === 'Pint')||(this.state.metricL === 'Quart' && this.state.metricR === 'Quart')||(this.state.metricL === 'Gallons' && this.state.metricR === 'Gallons')||(this.state.metricL === 'Liters' && this.state.metricR === 'Liters')||(this.state.metricL === 'milliLiters' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR)})
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 48});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 16});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 8});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 0.5});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 0.25});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0625});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.236588125});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 236.588125}); 
    // TEASPOONS => VARIABLES ///////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0208333333});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.3333333333});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 0.1666666667});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0104166667});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0052083333});
            }else if (this.state.title=== 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0013020833});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0049289193});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 4.9289192708});
    // TABLESPOONS => VARIABLE /////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0625});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 3});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 0.5});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 0.03125});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 0.015625});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.00390625});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0147867578});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 14.786757812});
    // OUNCES => VARIABLE //////////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 0.125});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 6});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 2});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0625});
            }else if (this.state.title=== 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 0.03125});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0078125});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0295735156});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 29.573515625});
    //PINT => VARIABLES /////////////////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 2});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 96});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 32});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 16});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 0.5});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.125});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.47317625});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 473.17625});
    // QUART => VARIABLES //////////////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 4});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 192});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 64});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 1});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 2});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.25});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.9463525});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 946.3525});  
    // GALLONS => VARIABLES ///////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 16});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 768});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 256});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 128});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 8});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 4});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 3.78541});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 3785.41});  
    // LITERS => VARIABLES /////////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 4.2267548297});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 202.88423183});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 67.628077276});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 33.814038638});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 2.1133774149});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 1.0566887074});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.2641721769});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'milliLiters'))){
                this.setState({amountR: Number(this.state.amountR) * 1000});  
    // MILLILITERS => VARIABLES /////////
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Cups'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0042267548});
            }else if (this.state.title  === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Teaspoons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.2028842318});
            }else if (this.state.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Tablespoons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0676280773});
            }else if (this.state.title  === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Ounces'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0338140386});
            }else if (this.state.title  === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Pint'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0021133774});
            }else if (this.state.title  === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Quart'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0010566887});
            }else if (this.state.title  === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Gallons'))){
                this.setState({amountR: Number(this.state.amountR) * 0.0002641722});
            }else if (this.state.title  === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Liters'))){
                this.setState({amountR: Number(this.state.amountR) * 0.001}); 
    // AMOUNT INPUT R //////////////////
            }else if (this.state.title  === 'amountR' && ((this.state.metricL === 'Cups' && this.state.metricR === 'Cups')||(this.state.metricL === 'Teaspoons' && this.state.metricR === 'Teaspoons')||(this.state.metricL === 'Tablespoons' && this.state.metricR === 'Tablespoons')||(this.state.metricL === 'Ounces' && this.state.metricR === 'Ounces')||(this.state.metricL === 'Pint' && this.state.metricR === 'Pint')||(this.state.metricL === 'Quart' && this.state.metricR === 'Quart')||(this.state.metricL === 'Gallons' && this.state.metricR === 'Gallons')||(this.state.metricL === 'Liters' && this.state.metricR === 'Liters')||(this.state.metricL === 'milliLiters' && this.state.metricR === 'milliLiters'))){
                this.setState({amountL: this.state.amountR})
    // CUPS => VARIABLES ////////////////////
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 48});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 16});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountRe) * 8});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 0.5});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 0.25});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0625});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.236588125});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 236.588125}); 
    // TEASPOONS => VARIABLES ///////
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0208333333});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.3333333333});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 0.1666666667});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0104166667});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0052083333});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0013020833});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0049289193});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 4.9289192708});
    // TABLESPOONS => VARIABLE /////
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0625});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 3});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 0.5});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 0.03125});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 0.015625});
            }else if (this.state.title  === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.00390625});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0147867578});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 14.786757812});
    // OUNCES => VARIABLE //////////
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 0.125});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 6});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 2});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0625});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 0.03125});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0078125});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0295735156});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 29.573515625});
    //PINT => VARIABLES /////////////////
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 2});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 96});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 32});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 16});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 0.5});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.125});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.47317625});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 473.17625});
    // QUART => VARIABLES //////////////
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 4});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 192});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 64});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 1});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 2});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.25});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.9463525});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 946.3525});  
    // GALLONS => VARIABLES ///////
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 16});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 768});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 256});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 128});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 8});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 4});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountRe) * 3.78541});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 3785.41});  
    // LITERS => VARIABLES /////////
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 4.2267548297});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 202.88423183});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 67.628077276});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 33.814038638});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 2.1133774149});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 1.0566887074});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.2641721769});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'milliLiters'))){
                this.setState({amountL: Number(this.state.amountR) * 1000});  
    // MILLILITERS => VARIABLES /////////
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Cups'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0042267548});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Teaspoons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.2028842318});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Tablespoons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0676280773});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Ounces'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0338140386});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Pint'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0021133774});
            }else if (this.state.title=== 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Quart'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0010566887});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Gallons'))){
                this.setState({amountL: Number(this.state.amountR) * 0.0002641722});
            }else if (this.state.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Liters'))){
                this.setState({amountL: Number(this.state.amountR) * 0.001}); 
        }
    
    }
    
    amountAdj = (event) => {
// CUPS => VARIABLE ///////////////
        if (event.target.title === 'amountL' && ((this.state.metricL === 'Cups' && this.state.metricR === 'Cups')||(this.state.metricL === 'Teaspoons' && this.state.metricR === 'Teaspoons')||(this.state.metricL === 'Tablespoons' && this.state.metricR === 'Tablespoons')||(this.state.metricL === 'Ounces' && this.state.metricR === 'Ounces')||(this.state.metricL === 'Pint' && this.state.metricR === 'Pint')||(this.state.metricL === 'Quart' && this.state.metricR === 'Quart')||(this.state.metricL === 'Gallons' && this.state.metricR === 'Gallons')||(this.state.metricL === 'Liters' && this.state.metricR === 'Liters')||(this.state.metricL === 'milliLiters' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: event.target.value})
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 48});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 16});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 8});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 0.5});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 0.25});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.0625});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.236588125});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Cups' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 236.588125}); 
            this.setState({amountL: event.target.value})
// TEASPOONS => VARIABLES ///////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 0.0208333333});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 0.3333333333});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 0.1666666667});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 0.0104166667});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 0.0052083333});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.0013020833});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.0049289193});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Teaspoons' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 4.9289192708});
            this.setState({amountL: event.target.value})
// TABLESPOONS => VARIABLE /////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 0.0625});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 3});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 0.5});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 0.03125});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 0.015625});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.00390625});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.0147867578});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Tablespoons' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 14.786757812});
            this.setState({amountL: event.target.value})
// OUNCES => VARIABLE //////////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 0.125});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 6});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 2});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 0.0625});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 0.03125});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.0078125});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.0295735156});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Ounces' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 29.573515625});
            this.setState({amountL: event.target.value})
//PINT => VARIABLES /////////////////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 2});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 96});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 32});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 16});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 0.5});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.125});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.47317625});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Pint' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 473.17625});
            this.setState({amountL: event.target.value})
// QUART => VARIABLES //////////////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 4});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 192});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 64});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 1});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 2});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.25});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.9463525});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Quart' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 946.3525}); 
            this.setState({amountL: event.target.value}) 
// GALLONS => VARIABLES ///////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 16});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 768});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 256});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 128});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 8});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 4});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 3.78541});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Gallons' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 3785.41});  
            this.setState({amountL: event.target.value})
// LITERS => VARIABLES /////////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 4.2267548297});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 202.88423183});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 67.628077276});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 33.814038638});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 2.1133774149});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 1.0566887074});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.2641721769});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'Liters' && this.state.metricR === 'milliLiters'))){
            this.setState({amountR: Number(event.target.value) * 1000});  
            this.setState({amountL: event.target.value})
// MILLILITERS => VARIABLES /////////
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Cups'))){
            this.setState({amountR: Number(event.target.value) * 0.0042267548});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Teaspoons'))){
            this.setState({amountR: Number(event.target.value) * 0.2028842318});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Tablespoons'))){
            this.setState({amountR: Number(event.target.value) * 0.0676280773});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Ounces'))){
            this.setState({amountR: Number(event.target.value) * 0.0338140386});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Pint'))){
            this.setState({amountR: Number(event.target.value) * 0.0021133774});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Quart'))){
            this.setState({amountR: Number(event.target.value) * 0.0010566887});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Gallons'))){
            this.setState({amountR: Number(event.target.value) * 0.0002641722});
            this.setState({amountL: event.target.value})
        }else if (event.target.title === 'amountL'&&((this.state.metricL === 'milliLiters' && this.state.metricR === 'Liters'))){
            this.setState({amountR: Number(event.target.value) * 0.001}); 
            this.setState({amountL: event.target.value})
// AMOUNT INPUT R //////////////////
        }else if (event.target.title === 'amountR' && ((this.state.metricL === 'Cups' && this.state.metricR === 'Cups')||(this.state.metricL === 'Teaspoons' && this.state.metricR === 'Teaspoons')||(this.state.metricL === 'Tablespoons' && this.state.metricR === 'Tablespoons')||(this.state.metricL === 'Ounces' && this.state.metricR === 'Ounces')||(this.state.metricL === 'Pint' && this.state.metricR === 'Pint')||(this.state.metricL === 'Quart' && this.state.metricR === 'Quart')||(this.state.metricL === 'Gallons' && this.state.metricR === 'Gallons')||(this.state.metricL === 'Liters' && this.state.metricR === 'Liters')||(this.state.metricL === 'milliLiters' && this.state.metricR === 'milliLiters'))){
            this.setState({amountL: event.target.value})
            this.setState({amountR: event.target.value})
// CUPS => VARIABLES ////////////////////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 48});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 16});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 8});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 0.5});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 0.25});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.0625});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.236588125});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Cups' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 236.588125});
            this.setState({amountR: event.target.value}) 
// TEASPOONS => VARIABLES ///////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 0.0208333333});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 0.3333333333});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 0.1666666667});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 0.0104166667});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 0.0052083333});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.0013020833});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.0049289193});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Teaspoons' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 4.9289192708});
            this.setState({amountR: event.target.value})
// TABLESPOONS => VARIABLE /////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 0.0625});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 3});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 0.5});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 0.03125});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 0.015625});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.00390625});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.0147867578});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Tablespoons' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 14.786757812});
            this.setState({amountR: event.target.value})
// OUNCES => VARIABLE //////////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 0.125});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 6});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 2});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 0.0625});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 0.03125});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.0078125});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.0295735156});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Ounces' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 29.573515625});
            this.setState({amountR: event.target.value})
//PINT => VARIABLES /////////////////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 2});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 96});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 32});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 16});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 0.5});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.125});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.47317625});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Pint' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 473.17625});
            this.setState({amountR: event.target.value})
// QUART => VARIABLES //////////////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 4});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 192});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 64});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 1});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 2});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.25});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.9463525});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Quart' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 946.3525});
            this.setState({amountR: event.target.value})  
// GALLONS => VARIABLES ///////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 16});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 768});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 256});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 128});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 8});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 4});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 3.78541});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Gallons' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 3785.41}); 
            this.setState({amountR: event.target.value}) 
// LITERS => VARIABLES /////////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 4.2267548297});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 202.88423183});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 67.628077276});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 33.814038638});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 2.1133774149});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 1.0566887074});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.2641721769});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'Liters' && this.state.metricL === 'milliLiters'))){
            this.setState({amountL: Number(event.target.value) * 1000});  
            this.setState({amountR: event.target.value})
// MILLILITERS => VARIABLES /////////
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Cups'))){
            this.setState({amountL: Number(event.target.value) * 0.0042267548});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Teaspoons'))){
            this.setState({amountL: Number(event.target.value) * 0.2028842318});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Tablespoons'))){
            this.setState({amountL: Number(event.target.value) * 0.0676280773});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Ounces'))){
            this.setState({amountL: Number(event.target.value) * 0.0338140386});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Pint'))){
            this.setState({amountL: Number(event.target.value) * 0.0021133774});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Quart'))){
            this.setState({amountL: Number(event.target.value) * 0.0010566887});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Gallons'))){
            this.setState({amountL: Number(event.target.value) * 0.0002641722});
            this.setState({amountR: event.target.value})
        }else if (event.target.title === 'amountR'&&((this.state.metricR === 'milliLiters' && this.state.metricL === 'Liters'))){
            this.setState({amountL: Number(event.target.value) * 0.001}); 
            this.setState({amountR: event.target.value})
    }
    }

    amountAdjustedR = () => {
            // AMOUNT INPUT R //////////////////
        if ((this.state.metricL === 'Cups' && this.state.metricR === 'Cups')||(this.state.metricL === 'Teaspoons' && this.state.metricR === 'Teaspoons')||(this.state.metricL === 'Tablespoons' && this.state.metricR === 'Tablespoons')||(this.state.metricL === 'Ounces' && this.state.metricR === 'Ounces')||(this.state.metricL === 'Pint' && this.state.metricR === 'Pint')||(this.state.metricL === 'Quart' && this.state.metricR === 'Quart')||(this.state.metricL === 'Gallons' && this.state.metricR === 'Gallons')||(this.state.metricL === 'Liters' && this.state.metricR === 'Liters')||(this.state.metricL === 'milliLiters' && this.state.metricR === 'milliLiters')){
            this.setState({amountL: Number(this.state.amountR)})
        // CUPS => VARIABLES ////////////////////
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 48});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 16});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 8});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 0.5});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 0.25});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.0625});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.236588125});
        }else if (this.state.metricR === 'Cups' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 236.588125}); 
        // TEASPOONS => VARIABLES ///////
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 0.0208333333});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 0.3333333333});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 0.1666666667});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 0.0104166667});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 0.0052083333});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.0013020833});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.0049289193});
        }else if (this.state.metricR === 'Teaspoons' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 4.9289192708});
        // TABLESPOONS => VARIABLE /////
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 0.0625});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 3});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 0.5});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 0.03125});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 0.015625});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.00390625});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.0147867578});
        }else if (this.state.metricR === 'Tablespoons' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 14.786757812});
        // OUNCES => VARIABLE //////////
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 0.125});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 6});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 2});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 0.0625});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 0.03125});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.0078125});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.0295735156});
        }else if (this.state.metricR === 'Ounces' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 29.573515625});
        //PINT => VARIABLES /////////////////
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 2});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 96});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 32});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 16});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 0.5});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.125});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.47317625});
        }else if (this.state.metricR === 'Pint' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 473.17625});
        // QUART => VARIABLES //////////////
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 4});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 192});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 64});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 1});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 2});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.25});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.9463525});
        }else if (this.state.metricR === 'Quart' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 946.3525});  
        // GALLONS => VARIABLES ///////
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 16});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 768});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 256});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 128});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 8});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 4});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 3.78541});
        }else if (this.state.metricR === 'Gallons' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 3785.41});  
        // LITERS => VARIABLES /////////
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 4.2267548297});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 202.88423183});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 67.628077276});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 33.814038638});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 2.1133774149});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 1.0566887074});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.2641721769});
        }else if (this.state.metricR === 'Liters' && this.state.metricL === 'milliLiters'){
            this.setState({amountL: Number(this.state.amountR) * 1000});  
        // MILLILITERS => VARIABLES /////////
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Cups'){
            this.setState({amountL: Number(this.state.amountR) * 0.0042267548});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Teaspoons'){
            this.setState({amountL: Number(this.state.amountR) * 0.2028842318});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Tablespoons'){
            this.setState({amountL: Number(this.state.amountR) * 0.0676280773});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Ounces'){
            this.setState({amountL: Number(this.state.amountR) * 0.0338140386});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Pint'){
            this.setState({amountL: Number(this.state.amountR) * 0.0021133774});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Quart'){
            this.setState({amountL: Number(this.state.amountR) * 0.0010566887});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Gallons'){
            this.setState({amountL: Number(this.state.amountR) * 0.0002641722});
        }else if (this.state.metricR === 'milliLiters' && this.state.metricL === 'Liters'){
            this.setState({amountL: Number(this.state.amountR) * 0.001}); 
        }
    }

    amountAdjustedL = () => {
// CUPS => VARIABLE ///////////////
        if ((this.state.metricL === 'Cups' && this.state.metricR === 'Cups')||(this.state.metricL === 'Teaspoons' && this.state.metricR === 'Teaspoons')||(this.state.metricL === 'Tablespoons' && this.state.metricR === 'Tablespoons')||(this.state.metricL === 'Ounces' && this.state.metricR === 'Ounces')||(this.state.metricL === 'Pint' && this.state.metricR === 'Pint')||(this.state.metricL === 'Quart' && this.state.metricR === 'Quart')||(this.state.metricL === 'Gallons' && this.state.metricR === 'Gallons')||(this.state.metricL === 'Liters' && this.state.metricR === 'Liters')||(this.state.metricL === 'milliLiters' && this.state.metricR === 'milliLiters')){
            this.setState({amountR: this.state.amountL})
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR: Number(this.state.amountL) * 48});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR: Number(this.state.amountL) * 16});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Ounces'){
            this.setState({amountR: Number(this.state.amountL) * 8});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Pint'){
            this.setState({amountR: Number(this.state.amountL) * 0.5});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Quart'){
            this.setState({amountR: Number(this.state.amountL) * 0.25});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Gallons'){
            this.setState({amountR: Number(this.state.amountL) * 0.0625});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'Liters'){
            this.setState({amountR: Number(this.state.amountL) * 0.236588125});
        }else if (this.state.metricL === 'Cups' && this.state.metricR === 'milliLiters'){
            this.setState({amountR: Number(this.state.amountL) * 236.588125}); 
// TEASPOONS => VARIABLES ///////
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Cups'){
            this.setState({amountR: Number(this.state.amountL) * 0.0208333333});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR: Number(this.state.amountL) * 0.3333333333});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Ounces'){
            this.setState({amountR:  Number(this.state.amountL) * 0.1666666667});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0104166667});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Quart'){
            this.setState({amountR: Number(this.state.amountL) * 0.0052083333});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Gallons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0013020833});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'Liters'){
            this.setState({amountR: Number(this.state.amountL) * 0.0049289193});
        }else if (this.state.metricL === 'Teaspoons' && this.state.metricR === 'milliLiters'){
            this.setState({amountR:  Number(this.state.amountL) * 4.9289192708});
// TABLESPOONS => VARIABLE /////
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Cups'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0625});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR:  Number(this.state.amountL) * 3});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Ounces'){
            this.setState({amountR:  Number(this.state.amountL) * 0.5});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 0.03125});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Quart'){
            this.setState({amountR:  Number(this.state.amountL) * 0.015625});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Gallons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.00390625});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'Liters'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0147867578});
        }else if (this.state.metricL === 'Tablespoons' && this.state.metricR === 'milliLiters'){
            this.setState({amountR: Number(this.state.amountL) * 14.786757812});
// OUNCES => VARIABLE //////////
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Cups'){
            this.setState({amountR:  Number(this.state.amountL) * 0.125});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR:  Number(this.state.amountL) * 6});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR:  Number(this.state.amountL) * 2});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0625});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Quart'){
            this.setState({amountR:  Number(this.state.amountL) * 0.03125});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Gallons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0078125});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'Liters'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0295735156});
        }else if (this.state.metricL === 'Ounces' && this.state.metricR === 'milliLiters'){
            this.setState({amountR:  Number(this.state.amountL) * 29.573515625});
//PINT => VARIABLES /////////////////
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Cups'){
            this.setState({amountR:  Number(this.state.amountL) * 2});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR:  Number(this.state.amountL) * 96});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR:  Number(this.state.amountL) * 32});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Ounces'){
            this.setState({amountR:  Number(this.state.amountL) * 16});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Quart'){
            this.setState({amountR:  Number(this.state.amountL) * 0.5});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Gallons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.125});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'Liters'){
            this.setState({amountR:  Number(this.state.amountL) * 0.47317625});
        }else if (this.state.metricL === 'Pint' && this.state.metricR === 'milliLiters'){
            this.setState({amountR:  Number(this.state.amountL) * 473.17625});
// QUART => VARIABLES //////////////
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Cups'){
            this.setState({amountR:  Number(this.state.amountL) * 4});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR:  Number(this.state.amountL) * 192});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR:  Number(this.state.amountL) * 64});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Ounces'){
            this.setState({amountR:  Number(this.state.amountL) * 1});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 2});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Gallons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.25});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'Liters'){
            this.setState({amountR:  Number(this.state.amountL) * 0.9463525});
        }else if (this.state.metricL === 'Quart' && this.state.metricR === 'milliLiters'){
            this.setState({amountR:  Number(this.state.amountL) * 946.3525});  
// GALLONS => VARIABLES ///////
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Cups'){
            this.setState({amountR:  Number(this.state.amountL) * 16});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR:  Number(this.state.amountL) * 768});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR:  Number(this.state.amountL) * 256});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Ounces'){
            this.setState({amountR:  Number(this.state.amountL) * 128});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 8});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Quart'){
            this.setState({amountR:  Number(this.state.amountL) * 4});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'Liters'){
            this.setState({amountR:  Number(this.state.amountL) * 3.78541});
        }else if (this.state.metricL === 'Gallons' && this.state.metricR === 'milliLiters'){
            this.setState({amountR:  Number(this.state.amountL) * 3785.41});  
// LITERS => VARIABLES /////////
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Cups'){
            this.setState({amountR: Number(this.state.amountL) * 4.2267548297});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR: Number(this.state.amountL) * 202.88423183});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR: Number(this.state.amountL) * 67.628077276});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Ounces'){
            this.setState({amountR: Number(this.state.amountL) * 33.814038638});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 2.1133774149});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Quart'){
            this.setState({amountR:  Number(this.state.amountL) * 1.0566887074});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'Gallons'){
            this.setState({amountR: Number(this.state.amountL) * 0.2641721769});
        }else if (this.state.metricL === 'Liters' && this.state.metricR === 'milliLiters'){
            this.setState({amountR: Number(this.state.amountL) * 1000});  
// MILLILITERS => VARIABLES /////////
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Cups'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0042267548});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Teaspoons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.2028842318});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Tablespoons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0676280773});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Ounces'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0338140386});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Pint'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0021133774});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Quart'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0010566887});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Gallons'){
            this.setState({amountR:  Number(this.state.amountL) * 0.0002641722});
        }else if (this.state.metricL === 'milliLiters' && this.state.metricR === 'Liters'){
            this.setState({amountR:  Number(this.state.amountL) * 0.001}); 
    }
    }

    closeCalc = () => { 
        document.getElementById('calculatorNav').style.height  = "0";
        document.getElementById('calculatorNav').style.opacity = "0";
    }

        componentDidUpdate(prevState, prevProps) {
            if(this.state.metricL !== prevProps.metricL){
                this.amountAdjustedL();
            }else if (this.state.metricR !== prevProps.metricR){
                this.amountAdjustedR();
                
            }
        }

        render(){
            return(
                <div id="calculatorNav" className="calculatorContainer">
                    <div onClick={this.closeCalc} className="closebtn closeButton">&times;</div>
                    <select title="metricL" onChange={this.selectOptionsL} className="mySelect">
                                <option title='Cups' onSelect={this.selectOptionsL}>Cups</option>
                                <option title='Teaspoons' onSelect={this.selectOptionsL}>Teaspoons</option>
                                <option title='Tablespoons' onSelect={this.selectOptionsL}>Tablespoons</option>
                                <option title='Pounds' onSelect={this.selectOptionsL}>Pounds</option>
                                <option title='Ounces' onSelect={this.selectOptionsL}>Ounces</option>
                                <option title='Pint' onSelect={this.selectOptionsL}>Pint</option>
                                <option title='Quart' onSelect={this.selectOptionsL}>Quart</option>
                                <option title='Gallons' onSelect={this.selectOptionsL}>Gallons</option>
                                <option title='Liters' onSelect={this.selectOptionsL}>Liters</option>
                                <option title='milliLiters' onSelect={this.selectOptionsL}>milliLiters</option>
                            </select>
                            <input className='inputAmount' onChange={this.amountAdj} placeholder="0" title="amountL" type="text" value={this.state.amountL}/>
                            <h3 style={{color: 'tomato', paddingRight: '0.5rem', paddingLeft: '0.5rem'}}>=</h3>
                            <select title="metricR" onChange={this.selectOptionsR} className="mySelect">
                                <option title='CupsR' onSelect={this.selectOptionsR}>Cups</option>
                                <option title='TeaspoonsR' onSelect={this.selectOptionsR}>Teaspoons</option>
                                <option title='TablespoonsR' onSelect={this.selectOptionsR}>Tablespoons</option>
                                <option title='PoundsR' onSelect={this.selectOptionsR}>Pounds</option>
                                <option title='OuncesR' onSelect={this.selectOptionsR}>Ounces</option>
                                <option title='PintR' onSelect={this.selectOptionsR}>Pint</option>
                                <option title='QuartR' onSelect={this.selectOptionsR}>Quart</option>
                                <option title='GallonsR'onSelect={this.selectOptionsR}>Gallons</option>
                                <option title='LitersR' onSelect={this.selectOptionsR}>Liters</option>
                                <option title='milliLitersR' onSelect={this.selectOptionsR}>milliLiters</option>
                            </select>
                            <input className='inputAmount' onChange={this.amountAdj} placeholder="0" title="amountR" type="text" value={this.state.amountR}/>
                </div>
            );
        }
    }
export default Calculator;