import React from 'react';

import '../Styles/nomsstyle2.css';
import './Recipes/recipe.css';

export default function Loadmore({loadMore, totalResults}) {
    return(
        <div onClick={loadMore}>
            {/* <div title="loadMoreBuffer" style={{cursor: 'pointer', color: 'tomato', width: '100vw', paddingBottom: '0.5rem', opacity: '0', marginBottom: '-2rem'}}>loadMore</div> */}
            <div className={totalResults < 100?'loadmore-invisible':'loadmore'}>loadMore</div>
            <div title="loadMoreBuffer" style={{cursor: 'pointer', color: 'tomato', width: '100vw', marginBottom: '3.25rem', opacity: '0'}}>loadMore</div>
        </div>

    );
}
