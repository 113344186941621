import React, { useEffect, useState } from 'react';
// import  orangeCart  from '../Assets/Images/groceryListOrangeCart.png';
import  calculator  from '../Assets/Images/calculator.png';
import Calculator from '../Calculator/Calculator';
import { FaStar } from "react-icons/fa";
import '../../Styles/nomsstyle2.css';
import '../GroceryList/groceryList.css';
import './recipe.css';

const RecipeDetails = ({ingredientAmountPerServing, adjustServingAmount, quantityToList, adjustRecipePrice, servings, pricePerServing, readyInMinutes, save2Fav, savFav, recipeClosed, recipeTitle, adjustServings, recipeInstructions, recipeIngredients, recipeImage, addToList}) => {
    // recipeIngredients
    // ingredientAmountPerServing
    // const [ingredientDetails, setIngredientDetails] = useState([[ingredientAmountPerServing.map(amount => amount)], [recipeIngredients.map(ingredients => ingredients)]])
    const [ingredientCount, setIngredientCount] = useState(recipeIngredients.length);


    useEffect(() => {
        adjustRecipePrice();
        // setIngredientDetails([[ingredientAmountPerServing.map(amount => amount)], [recipeIngredients.map(ingredients => ingredients)]])
        setIngredientCount(recipeIngredients.length)
    }, [servings, adjustRecipePrice, adjustServingAmount, recipeIngredients, ingredientAmountPerServing]);

    const openCalc = () => { 
        document.getElementById('calculatorNav').style.height = "15vmin";
        document.getElementById('calculatorNav').style.opacity = "1";
    }

    const closeCalc = () => { 
        document.getElementById('calculatorNav').style.height = "0";
        document.getElementById('calculatorNav').style.opacity = "0";
    }
    return(
        <div>
            <div id="mySidenavRecipe" className="sidenavRecipe">  
                <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems:'center'}}> 
                    <div className="closebtn closeButton closeBtnPad" onClick={recipeClosed}>&times;</div>
                    <img onClick={() => openCalc()} onDoubleClick={() => closeCalc()} className="calculator" alt="calculator" src={calculator}/>
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: "space-evenly"}}><div onClick={adjustServings} title="increase" className="more-less">+</div><div className="recipeAmounts">servings: <div className='servingNumber'>{servings}</div></div><div onClick={adjustServings} title="decrease" className="more-less">-</div></div><div className="recipeAmounts">estimatedPrice: <div className='servingNumber'>${(pricePerServing).toFixed(2)}</div></div><div className="recipeAmounts">readyIn: <div className="servingNumber">{readyInMinutes}</div>min</div><div className="recipeAmounts">Ingredients: <div className='servingNumber'>{ingredientCount}</div></div>
                </div>    
                <div className=''>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0rem 1rem 0rem 1rem'}}>
                        <div className='recipeTitle'>{recipeTitle}</div>
                        <div onClick={save2Fav} className={savFav?'savFavActive':'saveFav'}><FaStar /></div>
                    </div>
                    <div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img alt="recipeImage" src={recipeImage} style={{justifySelf: 'center', padding: '1em', position: 'absolute', zIndex: '0', opacity: '0.3', width: '90vw'}}/>
                        </div>
    {/* Working On Servings Updating  */}
                    <div className='ingredientOuterContainer'>
                        <div className="ingredientAmountContainer">
                            <div className=''>
                                {ingredientAmountPerServing.map(amount => {
                                    return <div onClick={quantityToList} className='amountPosition'>{(((amount * servings) * 10) / 10).toFixed(2)}</div>
                                })}
                            </div>
                            <div className=''>
                                {recipeIngredients.map((ingredients, i) => {
                                    return (
                                        <div key={i} className='ingredientContainer ingredientText'>
                                            <div className='recipeIngredientContainer'>{ingredients.unit}</div><div id={ingredients.id} onClick={addToList} title={ingredients.name} className='ingredientPosition ingredientName'>{ingredients.originalName}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
    {/* Working On Servings Updating  */}
                        <div className='ingredientOuterContainer'>
                            <div className="recipeStepInsideContainer">
                                <div>
                                    {recipeInstructions.map((step, i)=>{
                                        return <div key={i+200} className='recipeInstructions'>* {step}</div>
                                    })}
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
                <Calculator />   
            </div>  
                
        </div>
    )
}

export default RecipeDetails;