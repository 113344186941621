import React from 'react';
import '../GroceryList/groceryList.css';
import '../../Styles/nomsstyle2.css';
import './cookbook.css';
import groceryListSymbol from '../Assets/Images/groceryListOrangeCart.png';


const Cookbook = ({isSignedIn, openNav, savedRecipeOpened, groceryListOpen, savedRecipes, savedRecipesOff, closeCookBookNav}) => {
    return(
        <>
        {groceryListOpen?
            <div id="myCookBookSidenav" className="sidenavCookBook">
                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}> 
                    <div className="closeButton closeBtnPosition" onClick={closeCookBookNav}>&times;</div>
                    <div className='cookBookTitle'>CookBookFavorites</div>          
                </div>
                <div className='cookbookDisplay'>
                    {isSignedIn?
                    <>
                    {savedRecipes.map((recipe, i) => {
                        return (
                            <div className='recipeCardFront'>
                                <div key={recipe.id} style={{color: 'tomato'}}>
                                    <div style={{flexWrap: 'wrap', alignSelf: 'center', justifySelf: 'center'}}>
                                        {recipe.title}
                                    </div>
                                    <img onClick={savedRecipeOpened} alt={recipe.id} className="recipeCard" title={recipe.title} src={recipe.image}/> 
                                </div>
                            </div>
                        )
                    })
                    }
                    </>
                    :
                    <>
                       {savedRecipesOff.map((recipe, i) => {
                        return (
                            <div className='recipeCardFront'>
                                <div style={{color: 'tomato'}}>
                                    <div style={{flexWrap: 'wrap', alignSelf: 'center', justifySelf: 'center'}}>
                                        {recipe.title}
                                    </div>
                                    <img onClick={savedRecipeOpened} alt={recipe.id} key={recipe.id} className="recipeCard" title={recipe.title} src={recipe.image}/> 
                                </div>
                            </div>
                        )
                    })
                    }
                    </>
                }
                </div>
            </div>
            :
            <div id="myCookBookSidenav" className="sidenavCookBook">
                <div id="openNavButton" onClick={openNav} className='groceryButtonContainer groceryListBtn'>
                    <div className='openGroceryDashes'>
                        &#9776;
                    </div>
                    <img 
                    alt="groceryListButton"
                    className="groceryListButton"
                    src={groceryListSymbol}/>
                </div>
            <div className="closeBtnPosition closeButton" onClick={closeCookBookNav}>&times;</div>
                <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}> 
                    <div className='cookBookTitle'>CookBookFavorites</div>          
                </div>
                <div className='cookbookDisplay'>
                    {savedRecipes.map((recipe) => {
                        return (
                            <div className='recipeCardFront'>
                                <div style={{color: 'tomato'}}>
                                    <div style={{flexWrap: 'wrap', alignSelf: 'center', justifySelf: 'center'}}>
                                    {recipe.title}
                                    </div>
                                    <img onClick={savedRecipeOpened} alt={recipe.id} key={recipe.id} className="recipeCard" title={recipe.title} src={recipe.image}/> 
                                </div>
                            </div>
                        )
                            })
                    }
                </div>
            </div>
        }
        </>
    )
}

export default Cookbook;