import React, { useState } from 'react';
import { TiArrowSortedUp } from "react-icons/ti";
import { TiArrowSortedDown } from "react-icons/ti";
import { BsBagFill } from "react-icons/bs";
import { BsBag } from "react-icons/bs";
import './groceryList.css';
import '../../Styles/nomsstyle2.css';

const GroceryList = ({isSignedIn, groceryList2, crossOff, didExpand, openNav, expandNav, compressNav, closeNav, closeCookBookNav, openCookBookNav, openMealPrepkNav, closeMealPrepNav, searchbarGrocery, groceryListFilter, groceryList, searchingGrocery, addToGroceryList, removeFromGroceryList, updateGroceryList}) => {
    const [ingredientAmount, setIngredientAmount] = useState(0);

    const keyboardEvents = (e) => {
        if(e.code === 'Enter'){
            addToGroceryList();
          }
      }

    return(
        <div style={{position: 'relative'}}>    
            <div id="mySidenav" className="sidenav">
                <div style={{display: 'flex'}}>
                    <div className="closebtn closeButton" onClick={closeNav}>&times;</div>
                    <div className="closeButton expandButton" onDoubleClick={compressNav} onClick={expandNav}>xpan</div>
                </div>
                <h5 onDoubleClick={closeCookBookNav} onClick={openCookBookNav} className='CookBookButton'>CookBookFavorites</h5>

                
                <h5 onDoubleClick={closeMealPrepNav} onClick={openMealPrepkNav} className='CookBookButton'>MealPrepBook</h5>
                
                <h2 onClick={groceryListFilter} className="groceryListTitle">GroceryList</h2>
                    {(isSignedIn&&groceryList2.length<1)||(!isSignedIn&&groceryList.length<1)? 
                    <div>
                        <p className="groceryList">empty list...</p>
                        <p className="groceryList">(double tap to remove item)</p>
                        <div className="groceryInputContainer">
                            <input onKeyDown={(e) => keyboardEvents(e)} onChange={searchingGrocery} className='groceryInput' type="text" placeholder="feed me ingedients..." value={searchbarGrocery}/> <button onClick={addToGroceryList} className="feedButton feedMeFont">N</button>
                        </div>
                    </div>
                    :
                    <div style={{height: '80vh', overflowY: 'scroll'}}>
                        {isSignedIn?
                    <ul>
                        {groceryList2.map((ingredient, key) => {
                            return (
                                <div key={key} style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                                    {didExpand?
                                    <div title={key} className='groceryListPointer groceryItem' onClick={crossOff} onDoubleClick={removeFromGroceryList} >
                                        <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>
                                        <BsBag />
                                        <div id={ingredient.name} className="groceryList listOpened">{ingredient.name}</div> 
                                        <select onChange={updateGroceryList} title="metric"  className="groceryListSelect" value={ingredient.unit}>
                                            <option title='Each' >Ea</option>
                                            <option title='Slices' >Slices</option>
                                            <option title='Cups' >C</option>
                                            <option title='Tsp'>Tsp</option>
                                            <option title='Tablespoons'>Tbsp</option>
                                            <option title='Pounds'>lbs</option>
                                            <option title='Oz'>Oz</option>
                                            <option title='Pint'>Pt</option>
                                            <option title='Quart'>Qt</option>
                                            <option title='Gal'>Gal</option>
                                            <option title='Liters'>L</option>
                                            <option title='milliLiters'>mL</option>
                                        </select>
                                                <div style={{paddingRight: '1rem', paddingLeft: '1rem', color: 'tomato', fontSize: '3vmin', fontWeight: 'bold'}}>{ingredient.amount}</div>
                                            <div style={{color: 'tomato'}}>
                                                <div id={ingredient.name + "Increase"} title="increaseItemAmount" className='amountIncrease' onClick={updateGroceryList}><TiArrowSortedUp /></div>
                                                <div id={ingredient.name + "Decrease"} title="decreaseItemAmount" className='amountIncrease' onClick={updateGroceryList}><TiArrowSortedDown /></div>
                                            </div>
                                    </div>
                                    </div>
                                    :
                                    <div title={key} className='groceryListPointer groceryItem itemContainer' onClick={crossOff} onDoubleClick={removeFromGroceryList} >
                                       
                                        <BsBagFill />
                                        <div id={ingredient.name} className="groceryList">{ingredient.name}</div> 
                                       
                                    </div>
                        }
                                </div>
                            )
                        })}
                    </ul>
                    :
                    <ul>
                    {groceryList.map((ingredient, key) => {
                        return (
                            <div key={key} style={{display: 'flex', justifyContent: 'center', paddingBottom: '1rem'}}>
                                {didExpand?
                                <div title={key} className='groceryListPointer groceryItem' onClick={crossOff} onDoubleClick={removeFromGroceryList} >
                                    <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center'}}>
                                    <BsBag />
                                    <div id={ingredient} className="groceryList listOpened">{ingredient}</div> 
                                    <select title="metric"  className="groceryListSelect">
                                        <option title='Each' >Ea</option>
                                        <option title='Slices' >Slices</option>
                                        <option title='Cups' >C</option>
                                        <option title='Tsp'>Tsp</option>
                                        <option title='Tablespoons'>Tbsp</option>
                                        <option title='Pounds'>lbs</option>
                                        <option title='Oz'>Oz</option>
                                        <option title='Pint'>Pt</option>
                                        <option title='Quart'>Qt</option>
                                        <option title='Gal'>Gal</option>
                                        <option title='Liters'>L</option>
                                        <option title='milliLiters'>mL</option>
                                    </select>
                                            <div style={{paddingRight: '1rem', paddingLeft: '1rem', color: 'tomato', fontSize: '3vmin', fontWeight: 'bold'}}>{ingredientAmount}</div>
                                        <div style={{color: 'tomato'}}>
                                            <div className='amountIncrease' onClick={() => setIngredientAmount(ingredientAmount + 1)}><TiArrowSortedUp /></div>
                                            <div className='amountIncrease' onClick={() => setIngredientAmount(ingredientAmount - 1)}><TiArrowSortedDown /></div>
                                        </div>
                                </div>
                                </div>
                                :
                                <div title={key} className='groceryListPointer groceryItem itemContainer' onClick={crossOff} onDoubleClick={removeFromGroceryList} >
                                   
                                    <BsBagFill />
                                    <div id={ingredient} className="groceryList">{ingredient}</div> 
                                   
                                </div>
                    }
                            </div>
                        )
                    })}
                </ul>
}
                    <div className="groceryInputContainer">
                        {/* GroceryList Searchbar */}
                        <input onKeyDown={(e) => keyboardEvents(e)} onChange={searchingGrocery} className='groceryInput' type="text" placeholder="feed me ingedients..." value={searchbarGrocery}/> 
                        {/* GroceryList Button */}
                        <div  onClick={addToGroceryList} className="feedMeFont">
                            N
                        </div>
                        {/* ^^^^^GroceryList Button^^^^*/}
                    </div>
                </div>
                    }
            </div>
            {/* groceryList Tab */}
            <div onClick={openNav} className='groceryButtonContainer'>
                <div className='openGroceryDashes'>
                    &#9776;
                </div>
            </div>
                  {/* Help Center */}
           
        </div>
    )
}

export default GroceryList;